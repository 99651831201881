/* eslint-disable import/no-named-as-default */
import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';

import app from 'redux/reducers/app/reducer';
import auth from 'redux/reducers/auth/reducer';
import errors from 'redux/reducers/errors/reducer';
import settings from 'redux/reducers/settings/reducer';
import popUp from 'redux/reducers/popUp/reducer';
import twoFa from 'redux/reducers/twoFa/reducer';
import confirmation from 'redux/reducers/confirmation/reducer';
import registrations from 'redux/reducers/registrations/reducer';
import transactions from 'redux/reducers/transactions/reducer';
import wallets from 'redux/reducers/wallets/reducer';
import company from 'redux/reducers/company/reducer';
import steps from 'redux/reducers/steps/reducer';
import currency from 'redux/reducers/currency/reducer';
import walletAddresses from 'redux/reducers/walletAddresses/reducer';
import bankAccounts from 'redux/reducers/bankAccounts/reducer';
import deposits from 'redux/reducers/deposits/reducer';
import trade from 'redux/reducers/trade/reducer';
import bonusSystem from 'redux/reducers/bonusSystem/reducer';
import download from 'redux/reducers/download/reducer';
import detailedView from 'redux/reducers/detailedView/reducer';
import messages from 'redux/reducers/messages/reducer';
import users from 'redux/reducers/users/reducer';
import multiAccounts from 'redux/reducers/multiAccounts/reducer';
import persistReducer from 'redux-persist/es/persistReducer';
import { persistConfig, settingsPersistConfig } from 'redux/persistConfig';

// ==========================================:
const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		app,
		auth,
		errors,
		registrations,
		settings: persistReducer(settingsPersistConfig, settings),
		popUp,
		twoFa,
		confirmation,
		transactions,
		wallets,
		deposits,
		company,
		steps,
		currency,
		walletAddresses,
		bankAccounts,
		trade,
		bonusSystem,
		download,
		detailedView,
		messages,
		users,
		multiAccounts,
	});

export default createRootReducer;
