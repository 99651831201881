import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { getFiatHistoryList } from '../../../redux/reducers/transactions/selectors';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { statusNames } from '../../TransactionHistory/utils';
import { getUserData } from '../../../redux/reducers/auth/selectors';
import DetailedViewFiatForm from '../DetailedViewForm/DetailedViewFiatForm';
import { getFiatDetailsRequest } from '../../../redux/reducers/detailedView/reducer';
import {
	getDetailedViewLoading,
	getFiatDetails,
} from '../../../redux/reducers/detailedView/selectors';

export interface IDepositHistoryFiatDetails {
	txId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DepositHistoryFiatDetails: FC<IDepositHistoryFiatDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	// const currentItemData = useSelector(getFiatHistoryList);
	// const userData = useSelector(getUserData);
	const fiatDetails = useSelector(getFiatDetails);
	const loading = useSelector(getDetailedViewLoading);

	useEffect(() => {
		if (txId) {
			/* const params: IGetTransactionHistoryRequestPayload = {
				apiParams: {
					current_page: 1,
					per_page: 100000000,
					id: Number(txId),
				},
			};
			dispatch(getFiatHistoryRequest(params)); */
			dispatch(getFiatDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	/* useEffect(() => {
		dispatch(getUserRequest());
	}, [dispatch]); */

	if (!fiatDetails && !loading) {
		return <div>No data</div>;
	}

	return (
		<>
			{!!fiatDetails && (
				<div className="detailed-view" key={fiatDetails.id}>
					<div className="input-wrap--col-2">
						<CustomInput
							label="Timestamp"
							value={`${moment.utc(fiatDetails.created_at).format('YYYY-MM-DD')} ${moment
								.utc(fiatDetails.created_at)
								.format('HH:mm:ss')}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="User Email"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${fiatDetails?.user?.email}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="User Full Name"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${fiatDetails?.user?.data.first_name} ${fiatDetails?.user?.data.last_name}`}
							type="text"
							disabled
						/>
						{fiatDetails?.user?.type === 'corporate' && (
							<CustomInput
								label="Company Name"
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								value={`${fiatDetails?.user?.data?.company_name}`}
								type="text"
								disabled
							/>
						)}
						<CustomInput
							label="Currency"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${fiatDetails.asset.code.toUpperCase()} ${fiatDetails.asset.name}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Gross"
							value={`${roundingNumber(
								fiatDetails.gross_fee,
								fiatDetails.asset.code,
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							)} ${fiatDetails.asset.code.toUpperCase()}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Fee"
							value={`${roundingNumber(
								fiatDetails.fee,
								fiatDetails.asset.code,
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							)} ${fiatDetails.asset.code.toUpperCase()}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Net"
							value={`${toFixedNumber(
								fiatDetails.net_fee,
								fiatDetails.asset.code,
								true,
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							)} ${fiatDetails.asset.code.toUpperCase()}`}
							type="text"
							disabled
						/>
						{fiatDetails?.admin_requisite?.account_number && (
							<>
								<CustomInput
									label="Destination"
									value={`${
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										fiatDetails?.admin_requisite?.bank_name
											? fiatDetails?.admin_requisite?.bank_name
											: '----'
									} ${
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										fiatDetails?.admin_requisite?.account_number
									}`}
									type="text"
									disabled
								/>
							</>
						)}
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<CustomInput label="Tx ID" value={`${fiatDetails.id}`} type="text" disabled />
						<CustomInput
							label="Status"
							value={`${statusNames[fiatDetails.status]}`}
							type="text"
							disabled
						/>
						{fiatDetails?.admin_requisite?.account_number && (
							<CustomInput
								label="Description"
								value={
									fiatDetails.description ||
									`${
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										fiatDetails?.admin_requisite?.bank_name
											? fiatDetails?.admin_requisite?.bank_name
											: '-'
									} ${
										// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
										fiatDetails?.admin_requisite?.account_number
											? fiatDetails?.admin_requisite?.account_number
											: ''
									}`
								}
								type="text"
								disabled
							/>
						)}
						<CustomInput label="Reference" value={fiatDetails.reference} type="text" disabled />
					</div>
					<DetailedViewFiatForm item={fiatDetails} />
				</div>
			)}
		</>
	);
};

export default DepositHistoryFiatDetails;
