import React from 'react';
import { useSelector } from 'react-redux';
import { ITableHeader } from './types';
import { getUserRoles } from '../../../../redux/reducers/auth/selectors';
import { ERole } from '../../../../redux/reducers/auth/types';
import { hasAnyRoleExcept } from '../../../../services/utils/checkRoles';

const TableHeader: React.FC<ITableHeader> = ({ children, onUpdate, onDelete, empty }) => {
	const userRoles = useSelector(getUserRoles);
	return (
		<>
			{!empty && (
				<div className="payment-methods-header">
					{!!children && <div className="payment-methods-header__title">{children}</div>}
					{hasAnyRoleExcept(userRoles, [
						ERole.Viewer,
						ERole.Initiator,
						ERole.Approver,
						ERole.Trader,
						ERole.Admin,
					]) && (
						<div className="payment-methods-header__actions">
							{!!onUpdate && (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button type="button" className="btn btn--icon btn--edit" onClick={onUpdate} />
							)}
							{!!onDelete && (
								// eslint-disable-next-line jsx-a11y/control-has-associated-label
								<button
									type="button"
									className="btn btn--icon-danger btn--delete"
									onClick={onDelete}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default TableHeader;
