import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
	createCryptoDetailsRequest,
	createCryptoDetailsSuccess,
	createFiatDetailsRequest,
	createFiatDetailsSuccess,
	createTradeDetailsRequest,
	createTradeDetailsSuccess,
	downloadCryptoFileRequest,
	getTradeDetailsRequest,
	getTradeDetailsSuccess,
	hideLoading,
	downloadFiatFileRequest,
	downloadTradeFileRequest,
	getCryptoDetailsSuccess,
	getCryptoDetailsRequest,
	getFiatDetailsSuccess,
	getFiatDetailsRequest,
} from './reducer';
import { api } from '../../../services';
import {
	ICreateCryptoDetailsRequest,
	ICreateFiatDetailsRequest,
	ICreateTradeDetailsRequest,
} from '../../../services/api/detailedView/types';
import { IGetTransactionHistoryRequestPayload } from '../transactions/types';
import { getCryptoHistoryRequest, getFiatHistoryRequest } from '../transactions/reducer';
import { responseErrors } from '../../../services/http/responseErrors';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { IFileDownloadPayload } from './types';

function* getTradeDetailsWorker({ payload }: PayloadAction<number>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.getTradeDetails, payload);
		yield put(getTradeDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
		yield put(getTradeDetailsSuccess(null));
	}
}

function* getCryptoDetailsWorker({ payload }: PayloadAction<number>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.getCryptoDetails, payload);
		yield put(getCryptoDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
		yield put(getCryptoDetailsSuccess(null));
	}
}

function* getFiatDetailsWorker({ payload }: PayloadAction<number>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.getFiatDetails, payload);
		yield put(getFiatDetailsSuccess(response));
	} catch (error) {
		yield put(hideLoading());
		if (axios.isAxiosError(error)) {
			notificationContainer(
				'Something went wrong, please try again later.',
				'user_blocked',
				'Error',
			);
		}
		yield put(getFiatDetailsSuccess(null));
	}
}

function* createTradeDetailsWorker({ payload }: PayloadAction<ICreateTradeDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postTradeDetails, payload.body);
		// const params: IGetTransactionHistoryRequestPayload = {
		// 	apiParams: {
		// 		current_page: 1,
		// 		per_page: 100000000,
		// 		id: Number(payload.id),
		// 	},
		// };
		// yield put(getTradeHistoryRequest(params));
		// yield put(createTradeDetailsSuccess(response));
		yield put(getTradeDetailsRequest(Number(payload.id)));
	} catch (error) {
		yield put(hideLoading());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* createFiatDetailsWorker({ payload }: PayloadAction<ICreateFiatDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postFiatDetails, payload.body);
		/* const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				current_page: 1,
				per_page: 100000000,
				id: Number(payload.id),
			},
		};
		yield put(getFiatHistoryRequest(params)); */
		yield put(getFiatDetailsRequest(Number(payload.id)));
		yield put(createFiatDetailsSuccess());
	} catch (error) {
		yield put(hideLoading());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* createCryptoDetailsWorker({ payload }: PayloadAction<ICreateCryptoDetailsRequest>) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.detailedView.postCryptoDetails, payload.body);
		/* const params: IGetTransactionHistoryRequestPayload = {
			apiParams: {
				current_page: 1,
				per_page: 100000000,
				id: Number(payload.id),
			},
		};
		yield put(getCryptoHistoryRequest(params)); */
		yield put(getCryptoDetailsRequest(Number(payload.id)));
		yield put(createCryptoDetailsSuccess());
	} catch (error) {
		yield put(hideLoading());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* downloadCryptoFileWorker({ payload }: PayloadAction<IFileDownloadPayload>) {
	try {
		yield call(api.detailedView.getCryptoFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/cloud/trs/crypto/download/${payload.file}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* downloadFiatFileWorker({ payload }: PayloadAction<IFileDownloadPayload>) {
	try {
		yield call(api.detailedView.getFiatFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/cloud/trs/fiat/download/${payload.file}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* downloadTradeFileWorker({ payload }: PayloadAction<IFileDownloadPayload>) {
	try {
		const response: string = yield call(api.detailedView.getTradeFile, payload.file);
		window.location.replace(
			`${process.env.REACT_APP_WEB_API_URL as string}/cloud/trs/trade/download/${payload.file}`,
		);
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* detailedViewSaga() {
	yield takeEvery(getTradeDetailsRequest, getTradeDetailsWorker);
	yield takeEvery(getCryptoDetailsRequest, getCryptoDetailsWorker);
	yield takeEvery(getFiatDetailsRequest, getFiatDetailsWorker);
	yield takeEvery(createTradeDetailsRequest, createTradeDetailsWorker);
	yield takeEvery(createFiatDetailsRequest, createFiatDetailsWorker);
	yield takeEvery(createCryptoDetailsRequest, createCryptoDetailsWorker);
	yield takeEvery(downloadCryptoFileRequest, downloadCryptoFileWorker);
	yield takeEvery(downloadFiatFileRequest, downloadFiatFileWorker);
	yield takeEvery(downloadTradeFileRequest, downloadTradeFileWorker);
}
