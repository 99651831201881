/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTokenRefreshTime } from 'services/utils/tokenRefresh';
import {
	EPermission,
	ERole,
	ERolePermissions,
	IAuthStore,
	ICodeForTwoFaRequest,
	ICreateNewPasswordPayload,
	ILoginPayload,
	ILoginResponse,
	ILoginResponseUser,
	ILogoutPayload,
	IPutPermissions,
	IResetPasswordPayload,
	IResetTwoFaRequestPayload,
	ITokenRefreshResponse,
	IUserFeesResponse,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	userData: null,
	accessToken: null,
	socketToken: null,
	refreshToken: null,
	isAuthenticated: false,
	registrLoader: false,
	logoutLoader: false,
	loginLoader: false,
	forgotPasswordLoader: false,
	createNewPasswordLoader: false,
	ipConfirmLoader: false,
	emailConfirm: false,
	emailConfirmLoader: false,
	emailResetConfirmTokenLoader: false,
	emailResetConfirmTokenMessage: false,
	generateSmsLoader: false,
	tokenRefreshTime: null,
	tokenUpdating: false,
	fees: null,
	permissions: null,
	role: null,
	roles: [],
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		getUserRequest: (state) => {
			state.loginLoader = true;
		},
		getUserSuccess: (state, { payload }: PayloadAction<ILoginResponseUser>) => {
			state.loginLoader = false;
			state.userData = payload;
		},
		getUserFeesSuccess: (state, { payload }: PayloadAction<IUserFeesResponse[]>) => {
			state.fees = payload;
		},
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
		},
		loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.socketToken = payload.socket_token;
			loginState.userData = payload.user_data;
			// loginState.tokenRefreshTime = getTokenRefreshTime();
		},
		logoutRequest: (state, action: PayloadAction<ILogoutPayload>) => {
			const loginRequestState = state;
			loginRequestState.logoutLoader = true;
		},
		refreshTokenRequest: (state) => {
			const loginRequestState = state;
			loginRequestState.tokenUpdating = true;
			loginRequestState.logoutLoader = true;
		},
		refreshTokenSuccess: (state, action: PayloadAction<ITokenRefreshResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.tokenUpdating = false;
			loginState.accessToken = payload.token;
			loginState.tokenRefreshTime = getTokenRefreshTime();
		},
		resetPasswordRequest: (state, action: PayloadAction<IResetPasswordPayload>) => {},
		createPasswordRequest: (state, action: PayloadAction<ICreateNewPasswordPayload>) => {},
		getTwoFaStatusRequest: (state, action) => {},
		getTwoFaStatusResponse: (state, action) => {},
		resetTwoFaRequest: (state, action: PayloadAction<IResetTwoFaRequestPayload>) => {},
		generateCodeForTwoFaRequest: (state, action: PayloadAction<ICodeForTwoFaRequest>) => {},
		getverificationFiles: (state) => {
			if (state.userData) {
				state.userData.status.name = 'pending';
			}
		},
		disableTwoFaRequestSuccess: (state) => {
			const stateData = state;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			stateData.userData.google2fa_enabled = 0;
		},
		enableTwoFaSuccess: (state) => {
			const stateData = state;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			stateData.userData.google2fa_enabled = 1;
		},
		authInitState: () => initialState,
		putPermissions: (state, action: PayloadAction<IPutPermissions | null>) => {
			const userPermissions = action.payload?.permissions;

			state.roles = []; // Ensure it's an empty array before assigning roles

			// Check for SuperAdmin role first
			if (action.payload && action.payload.is_admin) {
				state.roles.push(ERole.SuperAdmin);
			}

			// Define permission sets for each role
			const rolePermissions: Record<ERolePermissions, EPermission[]> = {
				[ERole.Viewer]: [
					EPermission.ViewHistory,
					EPermission.ViewStatement,
					EPermission.ViewTradeHistory,
					EPermission.DownloadReport,
				],
				[ERole.Initiator]: [
					EPermission.CreateDeposit,
					EPermission.CreateWithdraw,
					EPermission.ViewHistory,
				],
				[ERole.Approver]: [
					EPermission.ApproveDeposit,
					EPermission.ApproveWithdraw,
					EPermission.ViewHistory,
				],
				[ERole.Trader]: [EPermission.CreateTrade, EPermission.ViewTradeHistory],
				[ERole.BeneficiaryCreator]: [EPermission.ManageBeneficiary],
				[ERole.Admin]: [
					EPermission.ViewHistory,
					EPermission.ViewStatement,
					EPermission.ViewTradeHistory,
					EPermission.DownloadReport,
					EPermission.CreateDeposit,
					EPermission.CreateWithdraw,
					EPermission.ApproveDeposit,
					EPermission.ApproveWithdraw,
					EPermission.CreateTrade,
				],
			};

			state.permissions = action.payload?.permissions || null;

			if (userPermissions && !action.payload?.is_admin) {
				Object.entries(rolePermissions).forEach(([role, requiredPermissions]) => {
					if (requiredPermissions.every((perm) => userPermissions.includes(perm))) {
						/* if (role === ERole.Admin) {
							state.roles = [ERole.Admin];
							return;
						} */
						state.roles.push(role as ERole);
					}
				});
			}
		},
	},
});

export default auth.reducer;
export const {
	loginRequest,
	loginSuccess,
	logoutRequest,
	authInitState,
	refreshTokenRequest,
	refreshTokenSuccess,
	resetPasswordRequest,
	createPasswordRequest,
	getUserRequest,
	getUserSuccess,
	getTwoFaStatusRequest,
	getTwoFaStatusResponse,
	resetTwoFaRequest,
	generateCodeForTwoFaRequest,
	getverificationFiles,
	disableTwoFaRequestSuccess,
	enableTwoFaSuccess,
	getUserFeesSuccess,
	putPermissions,
} = auth.actions;
