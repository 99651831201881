import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { IMultiAccountsApi } from './types';
import { IAccount } from '../../../redux/reducers/multiAccounts/types';

export const multiAccounts: IMultiAccountsApi = {
	multiAccountsGet: () =>
		http.get(endpoint.multiAccounts.accounts).then((response) => response.data),

	multiAccountsSetCurrent: (accountId: number) =>
		http.get(endpoint.multiAccounts.accountsId(accountId)).then((response) => response.data),

	multiAccountsGetAccounts: (payload: any) =>
		http.get(endpoint.multiAccounts.accountsGet, { params: payload }).then((res) => res.data),

	multiAccountsInviteRequest: (payload) =>
		http.post(endpoint.multiAccounts.accountsInviteRequest, payload).then((res) => res.data),

	memberGet: (accountId: number, params: Record<string, any> = {}) =>
		http
			.get(endpoint.multiAccounts.member(accountId), { params })
			.then((response) => response.data),
};
