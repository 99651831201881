import React, { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { IPopUp } from '../types';
import {
	getMultiAccounts,
	getMultiAccountsSelectId,
} from '../../../redux/reducers/multiAccounts/selectors';
import { getUserRoles } from '../../../redux/reducers/auth/selectors';
import { hasRole } from '../../../services/utils/checkRoles';
import { ERole } from '../../../redux/reducers/auth/types';
import { ROUTES } from '../../../routes/constants';

const SuccessDepositPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const [countdown, setCountdown] = useState(10);
	const [isCounting, setIsCounting] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const history = useHistory();
	const userRoles = useSelector(getUserRoles);

	useEffect(() => {
		if (open) {
			setCountdown(10);
			setIsCounting(true);
		} else {
			setIsCounting(false);
		}
	}, [open]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isAdmin) {
			if (isCounting && countdown > 0) {
				timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
			} else if (isCounting && countdown === 0) {
				window.scrollTo(0, 0);
				history.push(ROUTES.DEPOSITS_HISTORY_FIAT);
			}
		}
		return () => clearTimeout(timer);
	}, [isCounting, countdown, history, isAdmin]);

	// const multiAccounts = useSelector(getMultiAccounts);
	// const multiAccountsSelectId = useSelector(getMultiAccountsSelectId);
	// const activeItem = multiAccounts?.find((item) => item.pivot.account_id === multiAccountsSelectId);
	// const isAdmin = activeItem?.pivot.is_account_admin === 1;

	useEffect(() => {
		setIsAdmin(
			hasRole(userRoles, ERole.SuperAdmin) ||
				hasRole(userRoles, ERole.Admin) ||
				(hasRole(userRoles, ERole.Initiator) && hasRole(userRoles, ERole.Approver)),
		);
	}, [userRoles]);

	return (
		<>
			<Popup open={open} onClose={closeModal} closeOnDocumentClick={false}>
				<div className="modal">
					<div
						className="close"
						onClick={() => {
							closeModal();
						}}
					/>
					<div className="popup">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header popup-header--mb-20">
							<p className="popup-header__title">Your deposit request has been received.</p>
						</div>
						<div className="popup-icon popup-icon--type2">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="58"
								height="58"
								viewBox="0 0 58 58"
								fill="none"
							>
								<path
									d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
									fill="#007F00"
									stroke="#007F00"
									strokeOpacity="0.06"
									strokeWidth="16"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
								<path
									d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
									stroke="white"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						<div className="popup-text">
							<p>
								Thank you for submitting your deposit request. Once you complete the transfer to the
								the account provided, we will update your balance too accordingly.
								<br />
								<br />
								If you have any issues or would like to cancel your request, please reach out to our
								team at <a href="mailto:support@oneify.money">support@oneify.money</a>
							</p>
						</div>
						{isAdmin ? (
							<div className="instant-exchange-confirm-list-note">
								<p>
									New deposit must be approved on the Deposit History page before being processed.
									You’ll be redirected automatically.
								</p>
							</div>
						) : null}

						<div className="popup-footer">
							<div className="popup-submit">
								{isAdmin ? (
									<button
										onClick={() => history.push('/history/deposit-fiat')}
										type="button"
										className="btn btn-primary btn--full"
									>
										Go to Deposit History ({countdown})
									</button>
								) : (
									<button
										onClick={() => history.push(ROUTES.WALLETS.INDEX)}
										type="button"
										className="btn btn-primary btn--full"
									>
										Home
									</button>
								)}
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default SuccessDepositPopUp;
