import React, { useEffect, useState } from 'react';
import Dashboard from 'layouts/Dashboard';
import loginBannerImage from 'assets/dist/img/login-banner@2x-min.png';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import IconSvg from '../../ui/Svg/IconSvg';
import {
	getMultiAccountsRequest,
	setCurrentAccountRequest,
} from '../../redux/reducers/multiAccounts/reducer';
import {
	getMultiAccounts,
	getMultiAccountsAdmin,
	getMultiAccountsRegular,
} from '../../redux/reducers/multiAccounts/selectors';
import { IAccount } from '../../redux/reducers/multiAccounts/types';
import Item from './Item/Item';
import { logoutRequest } from '../../redux/reducers/auth/reducer';

const LoginWorkspace = () => {
	const history = useHistory();
	const [bannerPositionLeft, setBannerPositionLeft] = useState(1);
	const dispatch = useDispatch();
	const multiAccounts = useSelector(getMultiAccounts);
	const multiAccountsAdmin = useSelector(getMultiAccountsAdmin);
	const multiAccountsRegular = useSelector(getMultiAccountsRegular);
	console.log(multiAccountsAdmin, multiAccountsRegular);

	useEffect(() => {
		dispatch(getMultiAccountsRequest());
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		if (loginBanner) {
			const loginBannerRect = loginBanner.getBoundingClientRect();
			const rect = loginBannerRect.left - bodyRect.left;
			setBannerPositionLeft(rect);
		}
	}, [dispatch]);

	const determineColor = (id: number) => {
		switch (id % 3) {
			case 0:
				return 'blue';
			case 1:
				return 'orange';
			case 2:
				return 'purple';
			default:
				return 'grey';
		}
	};

	const handleItemClick = (pivotAccountId?: number) => {
		if (pivotAccountId) {
			dispatch(setCurrentAccountRequest({ accountId: pivotAccountId, history }));
		}
	};

	return (
		<>
			<Helmet>
				<body className="login-page" />
				<style type="text/css">{`
            :root {
                --login-banner-left: ${bannerPositionLeft}px;
            }
        `}</style>
			</Helmet>
			<Dashboard>
				<section className="login-section">
					<div className="container">
						<div className="login workspace">
							<Link
								onClick={() => {
									dispatch(logoutRequest({ history }));
								}}
								to="/login"
								className="wallet-operations-header__back"
							>
								<IconSvg name="arrow-right" w="24" h="24" />
							</Link>
							<div className="login-form gap-0">
								<div className="login-form__title leading-1 mb-30">
									<p className="mb-15">Workspace</p>
									<span>Your account</span>
								</div>
								<div className="workspace-block">
									{multiAccountsAdmin?.map((item) => (
										<Item
											key={item.id}
											name={item.account_name}
											// Пробрасываем только pivotAccountId, если он существует
											pivotAccountId={item.pivot?.account_id}
											color={determineColor(item.id)}
											onClick={handleItemClick}
											// Если нужны другие данные, добавляйте новые пропы
										/>
									))}
								</div>

								<div className="login-form__title leading-1 mt-30 mb-30">
									<span>Accounts you manage</span>
								</div>
								<div className="workspace-block">
									{multiAccountsRegular?.map((item) => (
										<Item
											key={item.id}
											name={item.account_name}
											// Пробрасываем только pivotAccountId, если он существует
											pivotAccountId={item.pivot?.account_id}
											color={determineColor(item.id)}
											onClick={handleItemClick}
											// Если нужны другие данные, добавляйте новые пропы
										/>
									))}
								</div>
							</div>
						</div>
						<div className="login-banner" id="login_banner">
							<img src={loginBannerImage} alt="" />
							<p className="login-banner__text">
								Multi-currency accounts, foreign exchange and
								<br /> payments that streamline your global operations
							</p>
						</div>
					</div>
				</section>
			</Dashboard>
		</>
	);
};

export default LoginWorkspace;
