import { FC } from 'react';

const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p>Added at</p>
				</div>
				<div className="td">
					<p>Full Name</p>
				</div>
				<div className="td">
					<p>Email</p>
				</div>
				<div className="td">
					<p>Company name</p>
				</div>
				<div className="td">
					<p>Roles</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
