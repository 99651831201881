import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IMultiAccountsStore } from './types';

// ==========================================:
const getMultiAccountsState = (state: IStoreState): IMultiAccountsStore => state.multiAccounts;
export const getMultiAccounts = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) => multiAccounts.multiAccounts?.accounts,
);
export const getMultiAccountsAdmin = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) =>
		multiAccounts.multiAccounts?.accounts.filter((item) => item.pivot.is_account_admin),
);
export const getMultiAccountsRegular = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) =>
		multiAccounts.multiAccounts?.accounts.filter((item) => !item.pivot.is_account_admin),
);
export const getMultiRequestedParams = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) => multiAccounts.multiAccountsRequestedParams,
);
export const getMultiAccountsPage = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) => multiAccounts.multiAccountsPage,
);
export const getMultiAccountsMembers = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) => multiAccounts.multiAccountsMembers,
);
export const getMultiAccountsMembersLoading = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) => multiAccounts.multiAccountsMembersLoading,
);
export const getMultiAccountsSelectId = createSelector(
	[getMultiAccountsState],
	(multiAccounts: IMultiAccountsStore) => multiAccounts.multiAccountsSelectedId,
);
