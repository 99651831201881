import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { IPaymentMethodsHeader } from './types';
import { getUserRoles } from '../../../redux/reducers/auth/selectors';
import { ERole } from '../../../redux/reducers/auth/types';
import { hasAnyRoleExcept } from '../../../services/utils/checkRoles';

const PaymentMethodsHeader: FC<IPaymentMethodsHeader> = ({ title, buttonText, buttonHandler }) => {
	const userRoles = useSelector(getUserRoles);

	return (
		<div className="wallet-operations-header-wrap">
			<div className="wallet-operations-header">
				<p>{title}</p>
			</div>
			<div className="wallet-operations-header-right">
				{hasAnyRoleExcept(userRoles, [
					ERole.Viewer,
					ERole.Initiator,
					ERole.Approver,
					ERole.Trader,
					ERole.Admin,
				]) && (
					<button type="button" className="btn btn-primary" onClick={buttonHandler}>
						<svg
							width="21"
							height="20"
							viewBox="0 0 21 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M9.8763 15.8307V10.6224H4.66797V9.3724H9.8763V4.16406H11.1263V9.3724H16.3346V10.6224H11.1263V15.8307H9.8763Z"
								fill="white"
							/>
						</svg>
						{buttonText}
					</button>
				)}
			</div>
		</div>
	);
};

export default PaymentMethodsHeader;
