/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import Pagination from '../TransactionHistory/Pagination/Pagination';
import PerPageSelect from '../../ui/PerPageSelect/PerPageSelect';
import { getMultiAccountsPage } from '../../redux/reducers/multiAccounts/selectors';
import { getMultiAccountsAccountsRequest } from '../../redux/reducers/multiAccounts/reducer';

const YourAccounts = () => {
	const dispatch = useDispatch();

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState<number>(10);

	const multiAccountsPage = useSelector(getMultiAccountsPage);
	const { data = [], from = 1, last_page = 1 } = multiAccountsPage || {};
	useEffect(() => {
		dispatch(
			getMultiAccountsAccountsRequest({
				page: 1,
				perPage: 10,
			}),
		);
	}, [dispatch, currentPage, perPage]);

	const pageCount = last_page || 1;
	// Handler when user changes the "per page" dropdown:
	const handleSetPerPage = (value: number | undefined) => {
		if (!value) return;
		// Option A: reset to page 1:
		// setCurrentPage(1);

		// Option B: keep the user near the same "from" record:
		setCurrentPage(Math.ceil(from / value));
		setPerPage(value);
	};

	return (
		<div className="wallet-operations your-account">
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">
					<p>Your accounts</p>
				</div>
			</div>

			<div className="table-block table-block--mt-0">
				<div className="table-wrapper">
					<div className="table table--your-account">
						<TableHeader />
						{data.map((item) => (
							<TableBody key={item.id} item={item} />
						))}
					</div>

					<div className="table-footer">
						<div className="select-page">
							<PerPageSelect onChange={handleSetPerPage} />
						</div>

						{pageCount > 1 && (
							<div className="pagination-block">
								<Pagination
									pageCount={pageCount}
									currentPage={currentPage}
									onPageChange={setCurrentPage}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default YourAccounts;
