import { ERole } from '../../redux/reducers/auth/types';

export const hasRole = (roles: ERole[] | null, role: ERole) => roles?.includes(role) ?? false;

export const hasAnyRole = (roles: ERole[] | null, requiredRoles: ERole[]) =>
	roles ? requiredRoles.some((role) => roles.includes(role)) : false;

// Check if the user has at least one role from the entire role list except the excluded one
export const hasAnyRoleExcept = (userRoles: ERole[] | null, excludedRoles: ERole[]): boolean => {
	if (!userRoles) return false;

	return userRoles.some((role) => !excludedRoles.includes(role));
};
