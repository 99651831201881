import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import IconSvg from '../../../ui/Svg/IconSvg';
import { IAccount } from '../../../redux/reducers/multiAccounts/types';
import { Account } from '../../../services/api/multiAccounts/types';

// Define the props for TableBody component
interface IProps {
	item: Account;
}

const TableBody: FC<IProps> = ({ item }) => {
	const history = useHistory();
	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<div className="td-hidden-name">Account Name</div>
					<div className="td__wrap">
						<div className="td__text">{item.account_name}</div>
						<div className="td__more">{item?.pivot?.is_account_admin ? 'Main' : ''}</div>
					</div>
				</div>
				<div className="td">
					<div className="td-hidden-name">Roles</div>
					<div className="td__wrap">
						<div style={{ textTransform: 'capitalize' }} className="td__text">
							{item.roles.map((role) => role.name).join(', ')}
						</div>
					</div>
				</div>
				<div className="td td-right">
					<div className="td-hidden-name">Action</div>
					<div className="td__wrap">
						{item?.pivot?.is_account_admin ? (
							<>
								<div
									onClick={() => {
										history.push(`/your-accounts/${item.id}`);
									}}
									className="btn--icon"
								>
									<IconSvg name="eye" w="24" h="24" />
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};
export default TableBody;
