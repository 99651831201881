import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCryptoHistoryRequest } from 'redux/reducers/transactions/reducer';
import {
	getCryptoHistory,
	getCryptoHistoryList,
	getCryptoHistoryPageCount,
	getCryptoHistoryTotal,
} from 'redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from 'redux/reducers/transactions/types';
import PerPageSelect from 'ui/PerPageSelect/PerPageSelect';
import {
	downloadDepositWithdrawsRequest,
	downloadKeyRequest,
	downloadWithdrawalRequest,
} from 'redux/reducers/download/reducer';
import { useLocation } from 'react-router-dom';
import Filters from '../Filters/Filters';
import Pagination from '../Pagination/Pagination';
import TableBody from './TableBody/TableBody';
import TableHeader from './TableHeader/TableHeader';
import { ILocalFilter } from './types';
import IconSvg from '../../../ui/Svg/IconSvg';
import { IDownloadPayload } from '../../../redux/reducers/download/types';
import { getDownloadKey } from '../../../redux/reducers/download/selectors';
import { getUserData, getUserPermissions } from '../../../redux/reducers/auth/selectors';
import { getMultiAccountsSelectId } from '../../../redux/reducers/multiAccounts/selectors';
import { EPermission } from '../../../redux/reducers/auth/types';

const Crypto = () => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);
	const dispatch = useDispatch();
	const permissions = useSelector(getUserPermissions);
	const key = useSelector(getDownloadKey);
	const user = useSelector(getUserData);
	const [filters, setFilters] = useState({
		date_from: undefined,
		date_to: undefined,
		currency: undefined,
		type: 'withdrawal',
		status: undefined,
		tx_id: undefined,
	});
	const [localFilter, setLocalFilter] = useState<ILocalFilter>({
		value: 'all',
		state: 0,
	});

	const [perPage, setPerPage] = useState<number | undefined>(10);
	const itemList = useSelector(getCryptoHistoryList);
	const from = useSelector(getCryptoHistory)?.from || 1;
	const pageCount = useSelector(getCryptoHistoryPageCount);
	const total = useSelector(getCryptoHistoryTotal);
	const [currentPage, setCurrentPage] = useState(1);
	const [firstLoading, setFirstLoading] = useState(true);
	const multiAccountsSelectId = useSelector(getMultiAccountsSelectId);

	const changeFilter = (changeFilterValue: string) => {
		const math = Math.round(total / Number(perPage));
		if (localFilter.state === 0) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 1) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? +localFilter.state + 1 : 1,
			});
		}
		if (localFilter.state === 2) {
			setLocalFilter({
				value: changeFilterValue,
				state: changeFilterValue === localFilter.value ? 0 : 1,
			});
		}
	};

	useEffect(() => {
		const math = Math.round(total / Number(perPage));
		if (localFilter.state === 0) {
			const params: IGetTransactionHistoryRequestPayload = {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				apiParams: {
					current_page: math + 1 >= currentPage ? currentPage : 1,
					per_page: perPage === undefined ? 100000000 : perPage,
					...filters,
				},
				onFinally: () => setFirstLoading(false),
			};
			if (params.apiParams?.current_page === 1) {
				setCurrentPage(1);
			}
			dispatch(getCryptoHistoryRequest(params));
		} else if (localFilter.state === 1) {
			const params: IGetTransactionHistoryRequestPayload = {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				apiParams: {
					current_page: math + 1 >= currentPage ? currentPage : 1,
					per_page: perPage === undefined ? 100000000 : perPage,
					sort_column: localFilter.value,
					sort_direction: 'asc',
					...filters,
				},
				onFinally: () => setFirstLoading(false),
			};
			if (params.apiParams?.current_page === 1) {
				setCurrentPage(1);
			}
			dispatch(getCryptoHistoryRequest(params));
		} else {
			const params: IGetTransactionHistoryRequestPayload = {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				apiParams: {
					current_page: math + 1 >= currentPage ? currentPage : 1,
					per_page: perPage === undefined ? 100000000 : perPage,
					sort_column: localFilter.value,
					sort_direction: 'desc',
					...filters,
				},
				onFinally: () => setFirstLoading(false),
			};
			if (params.apiParams?.current_page === 1) {
				setCurrentPage(1);
			}
			dispatch(getCryptoHistoryRequest(params));
		}
	}, [dispatch, currentPage, perPage, filters, total, localFilter.state, localFilter.value]);

	const [fileTypeState, setFileTypeState] = useState('');
	const handleGetFile = (fileType: string) => {
		dispatch(downloadKeyRequest());
		setFileTypeState(fileType);
	};
	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		setTimeout(() => {
			const params: IDownloadPayload = {
				apiParams: {
					idUser: Number(user?.id),
					multiAccountsSelectId: Number(multiAccountsSelectId),
					fileName: fileTypeState,
					key,
					currency: 'crypto',
				},
				// onFinally: () => setFirstLoading(false),
			};
			dispatch(downloadWithdrawalRequest(params));
		}, 100);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [key]);
	const handleSetPerPage = (value: number | undefined) => {
		setPerPage(value);
		setCurrentPage(Math.ceil(from / Number(value)));
	};
	return (
		<>
			<div className="table-filter-wrap table-filter-wrap--px-16">
				<Filters
					filters={filters}
					onChange={setFilters}
					type="crypto"
					setCurrentPage={setCurrentPage}
					transactionType={filters.type}
				/>
			</div>

			<div className="table-block table-block--mt-0">
				<div className="table-wrapper table-wrapper--with-y-scroll">
					<div className="table table--transaction-history-crypto_withdraw">
						<TableHeader localFilter={localFilter} changeFilter={changeFilter} />

						{!firstLoading && <TableBody localFilter={localFilter} itemList={itemList} />}

						<div className="table-footer">
							<div className="select-page">
								<PerPageSelect onChange={handleSetPerPage} />
							</div>

							{!firstLoading && pageCount > 1 && (
								<div className="pagination-block">
									<Pagination
										pageCount={pageCount}
										currentPage={currentPage}
										onPageChange={setCurrentPage}
									/>
								</div>
							)}
							{permissions && permissions.includes(EPermission.DownloadReport) ? (
								<div className="button-wrap">
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										onClick={() => handleGetFile('csv')}
										type="button"
										className="btn btn--csv"
									/>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<button
										onClick={() => handleGetFile('xlsx')}
										type="button"
										className="btn btn--xlsx"
									/>
								</div>
							) : null}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Crypto;
