/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAccount,
	IMultiAccountsPayload,
	IMultiAccountsStore,
	ISetCurrentAccountPayload,
} from './types';
import {
	PaginatedMembersResponse,
	PaginatedResponse,
} from '../../../services/api/multiAccounts/types';

export const initialState: IMultiAccountsStore = {
	multiAccounts: null,
	multiAccountsMembers: null,
	multiAccountsMembersLoading: false,
	multiAccountsPage: null,
	multiAccountsSelectedId: null,
	multiAccountsLoading: false,

	multiAccountsRequestedParams: null,
};

const multiAccounts = createSlice({
	name: '@@multiAccounts',
	initialState,
	reducers: {
		getMultiAccountsRequestParams: (state, action) => {
			state.multiAccountsRequestedParams = action.payload;
		},
		getMultiAccountsRequest: (state) => {
			state.multiAccountsLoading = true;
		},
		getMultiAccountsSuccess: (state, action: PayloadAction<IMultiAccountsPayload>) => {
			state.multiAccounts = action.payload;
			state.multiAccountsLoading = false;
		},
		setCurrentAccountRequest: (state, _action: PayloadAction<ISetCurrentAccountPayload>) => {
			state.multiAccountsLoading = true;
		},
		setCurrentAccountSuccess: (state, action: PayloadAction<string | number>) => {
			state.multiAccountsSelectedId = action.payload;
			state.multiAccountsLoading = false;
		},
		getMultiAccountsAccountsRequest: (state, action: any) => {
			state.multiAccountsLoading = true;
		},
		getMultiAccountsAccountsSuccess: (state, action: PayloadAction<PaginatedResponse>) => {
			// Можно сохранить в multiAccounts или куда нужно
			state.multiAccountsPage = action.payload;
			state.multiAccountsLoading = false;
		},
		accountsInviteRequestRequest: (
			state,
			_action: PayloadAction<any>,
			multiRequestedParams?: any,
		) => {
			state.multiAccountsLoading = true;
		},
		accountsInviteRequestSuccess: (state) => {
			state.multiAccountsLoading = false;
		},
		getMemberRequest: (state, payload: any) => {
			state.multiAccountsMembersLoading = true;
		},
		getMemberSuccess: (state, action: PayloadAction<PaginatedMembersResponse>) => {
			state.multiAccountsMembers = action.payload;
			state.multiAccountsMembersLoading = false;
		},
		multiAccountsInitState: () => initialState,
	},
});

export default multiAccounts.reducer;

// Экспорт экшенов
export const {
	multiAccountsInitState,
	getMultiAccountsRequestParams,
	getMultiAccountsRequest,
	getMultiAccountsSuccess,
	setCurrentAccountRequest,
	setCurrentAccountSuccess,
	getMultiAccountsAccountsRequest,
	getMultiAccountsAccountsSuccess,
	accountsInviteRequestRequest,
	accountsInviteRequestSuccess,
	getMemberRequest,
	getMemberSuccess,
} = multiAccounts.actions;
