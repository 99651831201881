import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import IconSvg from 'ui/Svg/IconSvg';
import { roundingNumber } from 'services/utils/roundingNumber';

import { ROUTES } from 'routes/constants';
import { useDispatch, useSelector } from 'react-redux';
import { IPropsPopUp } from './types';
import { getWalletsRequest } from '../../../redux/reducers/wallets/reducer';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { getUserRoles } from '../../../redux/reducers/auth/selectors';
import { hasRole } from '../../../services/utils/checkRoles';
import { ERole } from '../../../redux/reducers/auth/types';

const SuccessCryptoWithdrawal: FC<IPropsPopUp> = ({
	open,
	closeModal,
	amount,
	address,
	code,
	assetName,
	network,
	chain,
	fee,
	clearParentForm,
	walletId,
}) => {
	const userRoles = useSelector(getUserRoles);
	const history = useHistory();
	const [countdown, setCountdown] = useState(10);
	const [isCounting, setIsCounting] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const dispatch = useDispatch();

	const handleGoToClick = () => {
		history.push(ROUTES.WITHDRAWALS_HISTORY_CRYPTO);
		clearParentForm();
	};

	const handleClose = () => {
		dispatch(getWalletsRequest());
		localStorage.asset = JSON.stringify({ asset_id: walletId });
		clearParentForm();
		// console.log('handleClose');
	};

	useEffect(() => {
		if (open) {
			setCountdown(10);
			setIsCounting(true);
		} else {
			setIsCounting(false);
		}
	}, [open]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isAdmin) {
			if (isCounting && countdown > 0) {
				timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
			} else if (isCounting && countdown === 0) {
				window.scrollTo(0, 0);
				history.push(ROUTES.WITHDRAWALS_HISTORY_CRYPTO);
			}
		}
		return () => clearTimeout(timer);
	}, [isCounting, countdown, history, isAdmin]);

	useEffect(() => {
		setIsAdmin(
			hasRole(userRoles, ERole.SuperAdmin) ||
				hasRole(userRoles, ERole.Admin) ||
				(hasRole(userRoles, ERole.Initiator) && hasRole(userRoles, ERole.Approver)),
		);
	}, [userRoles]);

	return (
		<Popup open={open} onClose={handleClose} closeOnDocumentClick={false}>
			<div className="modal">
				<div className="popup popup--width-480">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={handleClose} />
					<div className="popup-header popup-header--mb-20">
						<p className="popup-header__title">Withdrawal Request Submitted</p>
					</div>
					<div className="popup-icon popup-icon--type2">
						<svg
							width="58"
							height="58"
							viewBox="0 0 58 58"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
								fill="#007F00"
								stroke="#007F00"
								strokeOpacity="0.06"
								strokeWidth="16"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
							<path
								d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
								stroke="white"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</div>
					<div className="popup-body">
						<div className="successful-info">
							<div className="successful-info__item">
								<p>Cryptocurrency:</p>
								<span>
									{code} ({assetName})
								</span>
							</div>
							<div className="successful-info__item">
								<p>Wallet Address:</p>
								<span>{address}</span>
							</div>
							<div className="successful-info__item">
								<p>Network:</p>
								<span>
									{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
									{chain?.name === chain?.code ? `${chain?.name}` : `${chain?.name} ${chain?.code}`}
								</span>
							</div>
							<div className="successful-info__item">
								<p>Amount Requested:</p>
								<span>
									{roundingNumber(Number(amount), code)} {code}
								</span>
							</div>
							<div className="successful-info__item">
								<p>Withdrawal Fee:</p>
								<span>
									{roundingNumber(Number(fee), code)} {code}
								</span>
							</div>
							<div className="successful-info__item">
								<p>Withdrawal Amount:</p>
								<span>
									{toFixedNumber(Number(amount) - Number(fee), code, true)} {code}
								</span>
							</div>
						</div>
					</div>
					{isAdmin ? (
						<div className="instant-exchange-confirm-list-note">
							<p>
								New withdrawal must be approved on the Withdrawal History page before being
								processed. You’ll be redirected automatically.
							</p>
						</div>
					) : null}
					<div className="popup-footer">
						<div className="popup-submit popup-submit--type2">
							{isAdmin ? (
								<button
									type="button"
									className="btn btn-primary btn--full"
									onClick={handleGoToClick}
								>
									Go to Withdrawal History ({countdown})
								</button>
							) : (
								<button
									type="button"
									className="btn btn-primary btn--full"
									onClick={handleGoToClick}
								>
									Withdrawal History
								</button>
							)}
							{/* <button type="button" onClick={closeModal} className="btn btn-danger btn--full">
								Cancel
							</button> */}
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default SuccessCryptoWithdrawal;
