/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment/moment';
import { IUser } from '../../../services/api/multiAccounts/types';
import { statusClassNames, statusNames } from '../../TransactionHistory/utils';

interface IProps {
	item: any;
}

const TableBody: FC<IProps> = ({ item }) => {
	const history = useHistory();

	const hasStatus = Boolean(item?.status);
	let rolesString = '-';
	function formatRoleName(role: string): string {
		const words = role.replace(/_/g, ' ').toLowerCase().split(' ');
		return words
			.map((word, index) => (index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word))
			.join(' ');
	}

	if (hasStatus) {
		const rolesArray = Array.isArray(item?.roles) ? item.roles : [];
		rolesString = rolesArray.map((role: string) => formatRoleName(role)).join(', ') || '-';
	} else {
		rolesString =
			item?.roles?.length > 0
				? item.roles.map((role: { name: string }) => formatRoleName(role?.name)).join(', ')
				: '-';
	}
	const createdAtOld = rolesString === 'Admin' ? item?.data?.created_at : item?.added_at;
	const createdMoment = moment.utc(createdAtOld);
	const fullName = hasStatus
		? item?.full_name ?? '-'
		: `${item?.data?.first_name ?? ''} ${item?.data?.last_name ?? ''}`.trim();

	const email = hasStatus ? item?.email ?? '-' : item?.email || '-';

	const companyName = hasStatus ? item?.company_name ?? '-' : item?.data?.company_name || '-';

	console.log(rolesString);
	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<div className="td__wrap">
						{hasStatus ? (
							<div className="td">
								<div className="td-hidden-name">{hasStatus ? 'Status' : 'Added at'}</div>
								<div style={{ marginLeft: '-8px' }} className="td__wrap">
									<div className={`td__status ${statusClassNames[item.status]}`}>
										{statusNames[item.status]}
									</div>
								</div>
							</div>
						) : (
							<>
								{createdAtOld && (
									<>
										<div className="td__text___data">
											{createdMoment.isValid() ? createdMoment.format('YYYY-MM-DD') : '—'}
										</div>
										<div className="td__more">
											{createdMoment.isValid() ? createdMoment.format('HH:mm:ss') : '—'}
										</div>
									</>
								)}
							</>
						)}
					</div>
				</div>

				{/* Полное имя */}
				<div className="td">
					<div className="td-hidden-name">Full Name</div>
					<div className="td__wrap">
						<div style={{ color: '#141414' }} className="td__text">
							{fullName}
						</div>
					</div>
				</div>

				{/* Email */}
				<div className="td">
					<div className="td-hidden-name">Email</div>
					<div className="td__wrap">
						<div className="td__text">
							<a href="#!">{email}</a>
						</div>
					</div>
				</div>

				{/* Company name */}
				<div className="td">
					<div className="td-hidden-name">Company name</div>
					<div className="td__wrap">
						<div style={{ color: '#141414' }} className="td__text">
							{companyName}
						</div>
					</div>
				</div>

				{/* Roles */}
				<div className="td">
					<div className="td-hidden-name">Roles</div>
					<div className="td__wrap td-left">
						<div style={{ color: '#141414' }} className="td__text">
							{rolesString}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TableBody;
