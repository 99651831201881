import React, { FC, useEffect, useState } from 'react';

import { capitalize } from 'services/utils/strings';
import { statusClassNames, statusNames } from 'components/TransactionHistory/utils';
import { toMaxDecimals } from 'services/utils/numbers';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { de } from 'date-fns/locale';
import { ICryptoTableBodyProps } from '../types';
import { roundingNumber } from '../../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../../services/utils/toFixedNumber';
import { popUpOpen, setPopUpData } from '../../../../redux/reducers/popUp/reducer';
import { notificationContainer } from '../../../../services/utils/notificationContainer';
import { approveFiatTransactionRequest } from '../../../../redux/reducers/transactions/reducer';
import { getUserPermissions, getUserRoles } from '../../../../redux/reducers/auth/selectors';
import { EPermission, ERole } from '../../../../redux/reducers/auth/types';
import { hasRole } from '../../../../services/utils/checkRoles';

const TableBody: FC<ICryptoTableBodyProps> = ({ itemList, localFilter }) => {
	const { path } = useRouteMatch();
	const location = useLocation();
	const dispatch = useDispatch();
	const permissions = useSelector(getUserPermissions);
	const userRoles = useSelector(getUserRoles);
	const [itemFilter, setItemFilter] = useState(itemList);
	useEffect(() => {
		/* if (localFilter.state === 0) {
			setItemFilter(itemList);
		}
		if (localFilter.state === 1) {
			const max = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return a[localFilter.value] - b[localFilter.value];
			});
			setItemFilter(max);
		}
		if (localFilter.state === 2) {
			const min = itemList?.slice().sort((a, b) => {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				return b[localFilter.value] - a[localFilter.value];
			});
			setItemFilter(min);
		} */

		setItemFilter(itemList);
	}, [itemList, localFilter]);

	/* useEffect(() => {
		console.log(itemFilter);
	}, [itemFilter]); */
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	return (
		<div className="table-body">
			{itemFilter?.length ? (
				itemFilter?.map((item) => {
					const [date, time] = formatDateTime(item.created_at);

					return (
						<div key={item.id} className="tr">
							<div className="td">
								<div className="td-hidden-name">Timestamp</div>
								<div className="td__wrap">
									<div className="td__text">{moment.utc(item.created_at).format('YYYY-MM-DD')}</div>
									<div className="td__more">{moment.utc(item.created_at).format('HH:mm:ss')}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Account ID</div>
								<div className="td__wrap">
									<div className="td__text">{item.account_id}</div>
								</div>
							</div>
							{/* <div className="td">
								<div className="td-hidden-name">Type</div>
								<p>{capitalize(item.type)}</p>
							</div> */}
							<div className="td">
								<div className="td-hidden-name">Currency</div>
								<div className="td__wrap">
									<div className="td__text">{item?.asset?.code.toUpperCase()}</div>
									<div className="td__more">{item?.asset.name}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Gross</div>
								<div className="td__wrap">
									<div className="td__text">
										{roundingNumber(item.gross_fee, item?.asset?.code)}
									</div>
									<div className="td__more">{item?.asset?.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Fee</div>
								<div className="td__wrap">
									<div className="td__text">{roundingNumber(item.fee, item?.asset?.code)}</div>
									<div className="td__more">{item?.asset?.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Net</div>
								<div className="td__wrap">
									<div className="td__text">
										{toFixedNumber(item.net_fee, item?.asset?.code, true)}
									</div>
									<div className="td__more">{item?.asset?.code.toUpperCase()}</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Destination</div>
								<div className="td__wrap">
									<div className="td__text">
										{item?.bank?.bank_name ? item?.bank?.bank_name : '----'}
									</div>
									{item?.bank?.account_number && (
										<>
											<div className="td__more">{item?.bank?.account_number}</div>
										</>
									)}
								</div>
							</div>
							{/* <div className="td"> */}
							{/*	<div className="td-hidden-name">Tx ID</div> */}
							{/*	<p>???</p> */}
							{/* </div> */}
							{location.pathname.includes('withdrawal-fiat') ? (
								<div className="td">
									<div className="td-hidden-name">Beneficiary</div>
									<div className="td__wrap">
										<div className="td__text">{item?.bank_account?.bank_nickname}</div>
									</div>
								</div>
							) : null}
							<div className="td">
								<div className="td-hidden-name">Tx ID</div>
								<div className="td__wrap">
									<div className="td__text">
										<Link
											to={{
												pathname: `${path}/${item.id}`,
												state:
													path === '/history/withdrawal-fiat'
														? { fromWithdrawalFiatHistory: true, txId: item.id }
														: { fromDepositFiatHistory: true, txId: item.id },
											}}
										>
											{item.id}
										</Link>
									</div>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-hidden-name">Status</div>
								<div className="td__wrap">
									<div className={`td__status ${statusClassNames[item.status]}`}>
										{statusNames[item.status]}
									</div>
								</div>
							</div>
							<div className="td">
								<div className="td-hidden-name">Actions</div>
								<div className="td__wrap">
									{(hasRole(userRoles, ERole.Approver) ||
										hasRole(userRoles, ERole.Admin) ||
										hasRole(userRoles, ERole.SuperAdmin)) && (
										<div className="table-buttons flex-e">
											<button
												style={{
													opacity: item?.status === 'waiting_approval' ? 1 : 0,
													visibility: item?.status === 'waiting_approval' ? 'visible' : 'hidden',
													pointerEvents: item?.status === 'waiting_approval' ? 'auto' : 'none',
												}}
												onClick={() => {
													dispatch(popUpOpen('DeclineTransactionPopUp'));
													const { id, type } = item;
													dispatch(
														setPopUpData({
															title: 'Decline transaction',
															message: `You’re about to decline transaction ID ${id}`,
															data: {
																transaction_id: id,
																type,
																type_value: 'fiat',
															},
														}),
													);
												}}
												type="button"
												className="btn--icon-close btn--cancel"
												aria-label="Decline transaction"
											/>
											{location.pathname.includes('withdrawal-fiat') ? (
												<button
													style={{
														opacity: item?.status === 'waiting_approval' ? 1 : 0,
														visibility: item?.status === 'waiting_approval' ? 'visible' : 'hidden',
														pointerEvents: item?.status === 'waiting_approval' ? 'auto' : 'none',
													}}
													onClick={() => {
														const { id } = item;
														dispatch(approveFiatTransactionRequest({ transaction_id: id }));
													}}
													type="button"
													className="btn--icon-success btn--check"
													aria-label="Approve transaction"
													disabled={
														!!permissions && !permissions.includes(EPermission.ApproveWithdraw)
													}
												/>
											) : (
												<button
													style={{
														opacity: item?.status === 'waiting_approval' ? 1 : 0,
														visibility: item?.status === 'waiting_approval' ? 'visible' : 'hidden',
														pointerEvents: item?.status === 'waiting_approval' ? 'auto' : 'none',
													}}
													onClick={() => {
														const { id } = item;
														dispatch(approveFiatTransactionRequest({ transaction_id: id }));
													}}
													type="button"
													className="btn--icon-success btn--check"
													aria-label="Approve transaction"
													disabled={
														!!permissions && !permissions.includes(EPermission.ApproveDeposit)
													}
												/>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					);
				})
			) : (
				<div className="td text--center">Transactions is empty</div>
			)}
		</div>
	);
};

export default TableBody;
