import React, { FC, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../redux/reducers/auth/selectors';
import HeaderButtons from '../../../layouts-elements/HeaderButtons';

export interface IHistoryHeader {
	title?: string;
}

const HistoryHeader: FC<IHistoryHeader> = ({ title }) => {
	const [path, setPath] = useState('');

	const { pathname } = useLocation();

	useEffect(() => {
		const pathName = pathname.split('/');
		if (pathName[pathName.length - 1].startsWith('deposit')) {
			setPath('deposit');
		}
		if (pathName[pathName.length - 1].startsWith('withdrawal')) {
			setPath('withdrawal');
		}
	}, [pathname]);

	return (
		<div className="wallet-operations-header-wrap--mb-40">
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">{title || <p>Deposits & Withdrawals</p>}</div>
				<HeaderButtons />
			</div>

			<div className="tabs-buttons tabs-buttons--mb-0 tabs-buttons---border">
				<NavLink to={`${path}-crypto`}>Crypto</NavLink>
				<NavLink to={`${path}-fiat`}>Fiat</NavLink>
			</div>
		</div>
	);
};

export default HistoryHeader;
