import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleUploadIcon } from 'layouts-elements/DropFileCustom/config/ImageConfig';

import Input from '../../../ui/Formik/Input';
import TextAreaLinkify from './TextAreaLinkify';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

import InputFile from '../../../ui/Formik/InputFile';
import {
	createFiatDetailsRequest,
	downloadFiatFileRequest,
} from '../../../redux/reducers/detailedView/reducer';
import { getUserRoles } from '../../../redux/reducers/auth/selectors';
import { ERole } from '../../../redux/reducers/auth/types';
import { hasAnyRoleExcept } from '../../../services/utils/checkRoles';

const DetailedViewFiatForm = ({ item }: { item: any }) => {
	const userRoles = useSelector(getUserRoles);
	const dispatch = useDispatch();
	const initialValues = {
		client_notes: item.client_notes ? item.client_notes : '',
	};
	const [fileList, setFileList] = useState<any>([]);

	const validationSchema = yup.object().shape({
		client_notes: yup.string().max(255, 'Maximum length is 255 characters'),
	});

	const handleDownloadFiatFile = (file: string) => {
		dispatch(downloadFiatFileRequest({ file: file.split('/')[0] }));
	};

	return (
		<div className="detailed-view-form">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(value, { resetForm, setSubmitting }) => {
					setSubmitting(true);
					const formData = new FormData();
					fileList &&
						fileList.length &&
						fileList.forEach((file: File) => formData.append(`files[]`, file));
					const requestBody = {
						id: item.id,
						client_notes: value.client_notes,
					};
					Object.keys(requestBody).forEach((key) => {
						const valueRequest = requestBody[key as keyof typeof requestBody];
						if (valueRequest !== undefined) {
							formData.append(key, valueRequest.toString());
						}
					});
					dispatch(
						createFiatDetailsRequest({
							id: item.id,
							body: formData,
						}),
					);
					setFileList([]);
					resetForm();
					setSubmitting(false);
				}}
				enableReinitialize
				validateOnBlur
			>
				{({
					setFieldTouched,
					touched,
					values,
					errors,
					isValid,
					dirty,
					setFieldValue,
					isSubmitting,
				}) => (
					<>
						<Form>
							<Field
								title="Client Notes"
								type="textarea"
								placeholder=""
								name="client_notes"
								component={Input}
								disabled={
									!hasAnyRoleExcept(userRoles, [
										ERole.Viewer,
										ERole.Initiator,
										ERole.Approver,
										ERole.Trader,
										ERole.Admin,
										ERole.BeneficiaryCreator,
									])
								}
							/>

							<div className="input">
								<p className="input__name">Upload File</p>
								<InputFile
									fileList={fileList}
									setFileList={setFileList}
									disabled={
										!hasAnyRoleExcept(userRoles, [
											ERole.Viewer,
											ERole.Initiator,
											ERole.Approver,
											ERole.Trader,
											ERole.Admin,
											ERole.BeneficiaryCreator,
										])
									}
								/>
							</div>

							{/* <div className="input">
								<p className="input__name">Upload File</p>
								<input
									id="upload_file"
									name="upload_file"
									type="file"
									onChange={(event: any) => {
										const fileReader = new FileReader();
										fileReader.onload = () => {
											if (fileReader.readyState === 2) {
												setFieldValue('upload_file', fileReader.result);
											}
										};
										fileReader.readAsDataURL(event.target.files[0]);
										// setFieldValue('upload_file', event.currentTarget.files[0]);
									}}
								/>
							</div> */}

							<div className="input">
								<p className="input__name">Attached Files</p>
								<div className="input__links">
									{item.files?.length ? (
										item.files.map((file: any) => {
											return (
												<div
													key={file}
													className="input-file-preview__item"
													onClick={() => handleDownloadFiatFile(file)}
												>
													<img src={handleUploadIcon(file.split('.')[1])} alt="" />
													<span className="input-file-preview__name" title={file}>
														{file}
													</span>
												</div>
											);
										})
									) : (
										<div> No data</div>
									)}
								</div>
							</div>

							{/* <div className="input">
								<Field title="Notes" name="notes" component={TextAreaLinkify} />
							</div> */}

							<button
								type="submit"
								className="btn btn-primary btn--full"
								style={{ marginTop: 14 }}
								// disabled={!isValid || !dirty}
								disabled={isSubmitting}
							>
								Submit
							</button>
						</Form>
					</>
				)}
			</Formik>
		</div>
	);
};

export default DetailedViewFiatForm;
