import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DetailedViewWithdrawalFiatForm from '../DetailedViewForm/DetailedViewWithdrawalFiatForm';
import {
	getDetailedViewLoading,
	getFiatDetails,
} from '../../../redux/reducers/detailedView/selectors';
import { getFiatDetailsRequest } from '../../../redux/reducers/detailedView/reducer';

export interface IDepositHistoryFiatDetails {
	txId: string | number;
}

const WithdrawalHistoryFiatDetails: FC<IDepositHistoryFiatDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	// const currentItemData = useSelector(getFiatHistoryList);
	// const userData = useSelector(getUserData);
	const fiatDetails = useSelector(getFiatDetails);
	const loading = useSelector(getDetailedViewLoading);

	useEffect(() => {
		if (txId) {
			/* const params: IGetTransactionHistoryRequestPayload = {
				apiParams: {
					current_page: 1,
					per_page: 100000000,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					id: Number(txId),
				},
			};
			dispatch(getFiatHistoryRequest(params)); */
			dispatch(getFiatDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	/* useEffect(() => {
		dispatch(getUserRequest());
	}, [dispatch]); */

	if (!fiatDetails && !loading) {
		return <div>No data</div>;
	}

	return (
		<>
			{!!fiatDetails && (
				<div className="detailed-view" key={fiatDetails.id}>
					<DetailedViewWithdrawalFiatForm formData={fiatDetails} />
				</div>
			)}
		</>
	);
};

export default WithdrawalHistoryFiatDetails;
