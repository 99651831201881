import { call, put, takeEvery } from 'redux-saga/effects';
import { de } from 'date-fns/locale';
import {
	getMultiAccountsRequest,
	getMultiAccountsSuccess,
	setCurrentAccountRequest,
	setCurrentAccountSuccess,
	// -- Новые экшены:
	getMultiAccountsAccountsRequest,
	getMultiAccountsAccountsSuccess,
	accountsInviteRequestRequest,
	accountsInviteRequestSuccess,
	getMemberSuccess,
	getMemberRequest,
} from './reducer';
import { api } from '../../../services';
import { IMultiAccountsPayload } from './types';
import { getWalletsRequest } from '../wallets/reducer';
import { currencyDataRequest } from '../currency/reducer';
import { store } from '../../store';
import { popUpOpen, setPopUpData } from '../popUp/reducer';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';
import { PaginatedMembersResponse } from '../../../services/api/multiAccounts/types';
import { putPermissions } from '../auth/reducer';
import { IGetPermissionsResponse } from '../../../services/api/auth/types';

// Существующий worker
function* multiAccountsWorker() {
	try {
		const response: IMultiAccountsPayload = yield call(api.multiAccounts.multiAccountsGet);
		yield put(getMultiAccountsSuccess(response));
	} catch (error) {
		console.error('Error fetching multiAccounts:', error);
	}
}

// Существующий worker
function* setCurrentAccountWorker(action: ReturnType<typeof setCurrentAccountRequest>) {
	try {
		yield call(api.multiAccounts.multiAccountsSetCurrent, action.payload.accountId);
		const response: IGetPermissionsResponse = yield call(api.auth.getPermissions);
		yield put(putPermissions(response || null));
		yield put(setCurrentAccountSuccess(action.payload.accountId));
		action.payload.history.push('/wallets');
		store.dispatch(getWalletsRequest());
		store.dispatch(currencyDataRequest());
	} catch (error) {
		console.error('Error setting current account:', error);
	}
}

// -- Новый worker 1
function* multiAccountsGetAccountsWorker(action: any) {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response = yield call(api.multiAccounts.multiAccountsGetAccounts, action.payload);
		yield put(getMultiAccountsAccountsSuccess(response));
	} catch (error) {
		console.error('Error fetching accountsGet:', error);
	}
}
// -- Новый worker 2
function* accountsInviteRequestWorker(action: ReturnType<typeof accountsInviteRequestRequest>) {
	try {
		yield call(api.multiAccounts.multiAccountsInviteRequest, action.payload.params);
		yield put(popUpOpen('successPopUp'));
		yield put(
			setPopUpData({
				title: 'New user request submitted',
				confirmButtonTitle: 'Done',
			}),
		);
		yield put(accountsInviteRequestSuccess());
		yield put(
			getMemberRequest({
				accountId: action.payload.multiRequestedParams.accountId,
				...action.payload.multiRequestedParams,
			}),
		);
	} catch (error) {
		console.error('Error creating account invite:', error);
	}
}
function* setMemberWorker(action: any) {
	try {
		const { accountId, ...params } = action.payload;
		const response: PaginatedMembersResponse = yield call(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			api.multiAccounts.memberGet,
			accountId,
			params,
		);
		yield put(getMemberSuccess(response));
	} catch (error) {
		console.error('Error fetching member:', error);
	}
}
export function* multiAccountsSaga() {
	yield takeEvery(getMultiAccountsRequest, multiAccountsWorker);
	yield takeEvery(setCurrentAccountRequest, setCurrentAccountWorker);
	yield takeEvery(getMultiAccountsAccountsRequest, multiAccountsGetAccountsWorker);
	yield takeEvery(accountsInviteRequestRequest, accountsInviteRequestWorker);
	yield takeEvery(getMemberRequest, setMemberWorker);
}
