import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import HistoryHeader from './HistoryHeader/HistoryHeader';
import Trade from './Trade/Trade';
import DetailedView from '../DetailedView/DetailedView';
import { getUserPermissions } from '../../redux/reducers/auth/selectors';
import { EPermission } from '../../redux/reducers/auth/types';
import { ROUTES } from '../../routes/constants';

const TransactionsTrade = () => {
	const { path: matchPath } = useRouteMatch();
	const { state } = useLocation();
	const permissions = useSelector(getUserPermissions);

	if (permissions && !permissions.includes(EPermission.ViewTradeHistory)) {
		return <Redirect to={ROUTES.WALLETS.INDEX} />;
	}

	return (
		<div className="wallet-operations">
			{!state && <HistoryHeader />}
			<Switch>
				<Route exact path={`${matchPath}`} component={Trade} />
				<Route path={`${matchPath}/:id`} component={DetailedView} />
				{/* <Route exact path={`${matchPath}/crypto`} component={Crypto} /> */}
				{/* <Route exact path={`${matchPath}/fiat`} component={Fiat} /> */}

				<Redirect to={`${matchPath}`} />
			</Switch>
		</div>
	);
};

export default TransactionsTrade;
