import Auth from 'layouts/Auth';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import { Redirect } from 'react-router-dom';
import StatementTab from '../../../components/Statement';
import { ROUTES } from '../../../routes/constants';

const StatementPage = () => {
	return <Redirect to={ROUTES.WALLETS.INDEX} />;
	/* return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />

					<motion.div
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<StatementTab />
					</motion.div>
				</div>
			</section>
		</Auth>
	); */
};

export default StatementPage;
