import React, { FC, useEffect, useState, useCallback } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { ROUTES } from 'routes/constants';
import { useDispatch, useSelector } from 'react-redux';
import { IPopUp } from '../types';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { getWalletsRequest } from '../../../redux/reducers/wallets/reducer';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import {
	getMultiAccounts,
	getMultiAccountsSelectId,
} from '../../../redux/reducers/multiAccounts/selectors';
import { getUserRoles } from '../../../redux/reducers/auth/selectors';
import { hasRole } from '../../../services/utils/checkRoles';
import { ERole } from '../../../redux/reducers/auth/types';

interface IWithdrawPropsData {
	data: {
		amount: string;
		assetName: string;
		bankNickname: string;
		fee: string;
		indicatedAmount: number | string;
		code: string;
		walletId: string | number;
	};
}

const WithdrawConfirm: FC<IPopUp & IWithdrawPropsData> = ({ open, closeModal, data }) => {
	const userRoles = useSelector(getUserRoles);
	const history = useHistory();
	const [countdown, setCountdown] = useState(10);
	const [isCounting, setIsCounting] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const dispatch = useDispatch();

	const handleGoToClick = useCallback(() => {
		history.push(ROUTES.WITHDRAWALS_HISTORY_FIAT);
		closeModal();
	}, [history, closeModal]);

	const handleClose = useCallback(() => {
		dispatch(getWalletsRequest());
		if (data?.walletId) {
			localStorage.asset = JSON.stringify({ asset_id: data?.walletId });
		}
		closeModal();
	}, [dispatch, data, closeModal]);

	// const multiAccounts = useSelector(getMultiAccounts);
	// const multiAccountsSelectId = useSelector(getMultiAccountsSelectId);
	// const activeItem = multiAccounts?.find((item) => item.pivot.account_id === multiAccountsSelectId);
	// const isAdmin = activeItem?.pivot.is_account_admin === 1;

	useEffect(() => {
		if (open) {
			setCountdown(10);
			setIsCounting(true);
		} else {
			setIsCounting(false);
		}
	}, [open]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (isAdmin) {
			if (isCounting && countdown > 0) {
				timer = setTimeout(() => setCountdown((prev) => prev - 1), 1000);
			} else if (isCounting && countdown === 0) {
				closeModal();
				history.push(ROUTES.WITHDRAWALS_HISTORY_FIAT);
				window.scrollTo(0, 0);
			}
		}
		return () => clearTimeout(timer);
	}, [isCounting, countdown, history, isAdmin, closeModal]);

	useEffect(() => {
		setIsAdmin(
			hasRole(userRoles, ERole.SuperAdmin) ||
				hasRole(userRoles, ERole.Admin) ||
				(hasRole(userRoles, ERole.Initiator) && hasRole(userRoles, ERole.Approver)),
		);
	}, [userRoles]);

	return (
		<Popup open={open} closeOnDocumentClick={false} onClose={handleClose}>
			<div className="popup popup--width-480">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={handleClose} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Withdrawal Request Submitted</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="58"
						height="58"
						viewBox="0 0 58 58"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M29.0013 49.8307C40.4596 49.8307 49.8346 40.4557 49.8346 28.9974C49.8346 17.5391 40.4596 8.16406 29.0013 8.16406C17.543 8.16406 8.16797 17.5391 8.16797 28.9974C8.16797 40.4557 17.543 49.8307 29.0013 49.8307Z"
							fill="#007F00"
							stroke="#007F00"
							strokeOpacity="0.06"
							strokeWidth="16"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203" fill="#007F00" />
						<path
							d="M21.0469 28.6171L26.3437 33.9139L36.956 23.3203"
							stroke="white"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
				<div className="popup-body">
					<div className="successful-info">
						<div className="successful-info__item">
							<p>Currency:</p>
							<span>
								{data.code} ({data.assetName})
							</span>
						</div>
						<div className="successful-info__item">
							<p>Bank Account:</p>
							<span>{data.bankNickname}</span>
						</div>
						<div className="successful-info__item">
							<p>Amount Requested:</p>
							<span>
								{roundingNumber(data.indicatedAmount, data.code)} {data.code}
							</span>
						</div>
						<div className="successful-info__item">
							<p>Withdrawal Fee:</p>
							<span>
								{roundingNumber(data.fee, data.code)} {data.code}
							</span>
						</div>
						<div className="successful-info__item">
							<p>Withdrawal Amount:</p>
							<span>
								{toFixedNumber(Number(data.amount) - Number(data.fee), data.code, true)} {data.code}
							</span>
						</div>
					</div>
				</div>
				{isAdmin ? (
					<div className="instant-exchange-confirm-list-note">
						<p>
							New withdrawal must be approved on the Withdrawal History page before being processed.
							You’ll be redirected automatically.
						</p>
					</div>
				) : null}
				<div className="popup-footer">
					<div className="popup-submit popup-submit--type2">
						{isAdmin ? (
							<button onClick={handleGoToClick} type="button" className="btn btn-primary btn--full">
								Go to Withdrawal History ({countdown})
							</button>
						) : (
							<button onClick={handleGoToClick} type="button" className="btn btn-primary btn--full">
								Withdrawal History
							</button>
						)}
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default WithdrawConfirm;
