import { spawn } from 'redux-saga/effects';

import { authSaga } from './reducers/auth/saga';
import { confirmationSaga } from './reducers/confirmation/saga';
import { settingsSaga } from './reducers/settings/saga';
import { twoFaSaga } from './reducers/twoFa/saga';
import { registrationsSaga } from './reducers/registrations/saga';
import { transactionsSaga } from './reducers/transactions/saga';
import { walletsSaga } from './reducers/wallets/saga';
import { companySaga } from './reducers/company/saga';
import { currencySaga } from './reducers/currency/saga';
import { depositsSaga } from './reducers/deposits/saga';
import { walletAddressesSaga } from './reducers/walletAddresses/saga';
import { bankAccountsSaga } from './reducers/bankAccounts/saga';
import { bonusSystemSaga } from './reducers/bonusSystem/saga';
import { downloadSaga } from './reducers/download/saga';
import { detailedViewSaga } from './reducers/detailedView/saga';
import { messagesSaga } from './reducers/messages/saga';
import { usersSaga } from './reducers/users/saga';
import { multiAccountsSaga } from './reducers/multiAccounts/saga';

// ==========================================:
export default function* rootSagas() {
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(twoFaSaga);
	yield spawn(confirmationSaga);
	yield spawn(registrationsSaga);
	yield spawn(transactionsSaga);
	yield spawn(walletsSaga);
	yield spawn(depositsSaga);
	yield spawn(companySaga);
	yield spawn(currencySaga);
	yield spawn(walletAddressesSaga);
	yield spawn(bankAccountsSaga);
	yield spawn(bonusSystemSaga);
	yield spawn(downloadSaga);
	yield spawn(detailedViewSaga);
	yield spawn(messagesSaga);
	yield spawn(usersSaga);
	yield spawn(multiAccountsSaga);
}
