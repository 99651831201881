import { History } from 'history';

export interface IAuthStore {
	accessToken: string | null;
	socketToken: string | null;
	refreshToken: string | null;
	isAuthenticated: boolean;
	registrLoader: boolean;
	loginLoader: boolean;
	logoutLoader: boolean;
	forgotPasswordLoader: boolean;
	createNewPasswordLoader: boolean;
	ipConfirmLoader: boolean;
	emailConfirm: boolean;
	emailConfirmLoader: boolean;
	emailResetConfirmTokenLoader: boolean;
	emailResetConfirmTokenMessage: boolean;
	generateSmsLoader: boolean;
	userData: IUser | null;
	tokenRefreshTime: string | null;
	tokenUpdating: boolean;
	fees: IUserFeesResponse[] | null;
	permissions: EPermission[] | null;
	role: any;
	roles: ERole[];
}

// ==========================================:
export interface ILoginPayload {
	data: ILoginPayloadData;
	history: History;
}

export interface ILoginPayloadData {
	email: string;
	password: string;
	captcha: string;
	totp: string;
	time_zone: number;
}

export interface ILoginResponse {
	fees: IUserFeesResponse[];
	token: string;
	socket_token: string;
	user_data: ILoginResponseUser;
	token_expired_at: number;
}

export interface IUserFeesResponse {
	id: number;
	code: string;
	type: string;
	depositable: number;
	withdrawable: number;
	exchangeable: number;
	depositable_message: string | null;
	withdrawable_message: string | null;
	has_tag: number;
	decimals: number;
	name: string;
	deposit_max: number;
	deposit_min: number;
	withdraw_max: number;
	withdraw_min: number;
	exchange_min: number;
	img_path: string;
	fee: {
		id: number;
		asset_id: number;
		user_id: number;
		deposit_fee_percent: number;
		deposit_fee_fixed: number;
		withdraw_fee_percent: number;
		withdraw_fee_fixed: number;
		trade_fee_percent: number;
		trade_fee_fixed: number;
		trade_slippage: number;
		debit_fee: number;
		debit_percent: number;
		credit_fee: number;
		credit_percent: number;
		same_asset_type_trade_fee_percent: number;
		same_asset_type_trade_fee_fixed: number;
		created_at: string;
		updated_at: string;
	};
}

export interface ITokenRefreshResponse {
	token: string;
}

export interface ILoginResponseUser {
	id: number;
	email: string;
	email_confirmed: number;
	google2fa_enabled: number;
	type_id: number;
	status_id: number;
	kyc_message: null | string;
	last_login: number;
	blocked: string;
	is_active: boolean;
	last_activity: string;
	invite_key: string;
	created_at: number;
	username: string;
	invite_url: string;
	data: ILoginResponseUserData;
	status: ILoginResponseUserStatus;
	type: string;
}

export interface ILoginResponseUserData {
	user_id: number;
	first_name: null | string;
	last_name: null | string;
	phone: null | string;
	dob: null | string;
	country: null | string;
	state: null | string;
	city: null | string;
	street: null | string;
	post_code: null | number;
	created_at: string;
	updated_at: string;
	position: null | number | string;
	company: null | number | string;
}

export interface ILoginResponseUserStatus {
	id: number;
	name: string;
}

// ==========================================:
export interface IRegistrationPayload {
	acceptTerms: boolean;
	captcha: string;
	confirmPassword: string;
	cookiePolicy: boolean;
	email: string;
	password: string;
}

export interface IRegistrResponse {
	status: string;
	email_confirm_token: string;
	user: IRegistrResponseUser;
}

export interface IRegistrResponseUser {
	id: string;
	email: string;
	invite_key: string;
	created_at: number;
	invite_url: string;
}

// ==========================================:

export interface IipConfirmPayload {
	data: IipConfirmPayloadData;
	history: History;
}

export interface IipConfirmPayloadData {
	code: string;
	email: string;
}

// ==========================================:
export interface ILogoutPayload {
	history: History;
}

export interface ILogoutResponse {
	status: string;
}

// ==========================================:
export interface IEmailConfirmPayload {
	token: string | string[] | null;
	timestamp: string | string[] | null;
}

// ==========================================:
export interface IEmailResetConfirmTokenPayload {
	token: string | string[] | null;
	history: History;
}

// ==========================================:
export interface IForgotPasswordPayload {
	email: string;
}

export interface IForgotPasswordResponse {
	status: string;
	password_reset_token: string;
}

// ==========================================:
export interface ICreateNewPasswordPayload {
	data: ICreateNewPasswordFormData;
	history: History;
}

export interface ICreateNewPasswordFormData {
	password: string;
	token: string | null;
}

// ==========================================:
export interface IGenerateSmsData {
	phone: string;
}

export interface IGenerateSmsResponseData {
	status: string;
}
// ==========================================:

export interface IResetTwoFaPayload {
	email: string;
	reason: string;
}
export interface IResetTwoFaRequestPayload {
	email: string;
	reason: string;
	type: 1;
	captcha: string;
}

export type IGenerateSmsRequestPayload = IGenerateSmsData;
export type IGenerateSmsResponsePayload = IGenerateSmsResponseData;

// ==========================================:

export type IResetPasswordPayload = {
	email: string;
	totp: string;
	reason: string;
	captcha: string;
};

// ==========================================:

export interface IUser {
	id: number;
	email: string;
	email_confirmed: number;
	google2fa_enabled: number;
	type_id: null | number | string;
	status_id: number;
	kyc_message: null | number | string;
	last_login: null | number | string;
	blocked: string;
	is_active: boolean;
	last_activity: string;
	invite_key: string;
	created_at: number;
	username: string;
	invite_url: string;
	data: IUserData;
	status: IUserDataStatus;
	type: string;
}

export interface IUserData {
	user_id: null | number | string;
	first_name: null | string;
	last_name: null | string;
	phone: null | number | string;
	dob: null | string;
	country: null | string;
	state: null | string;
	city: null | string;
	street: null | string;
	post_code: null | number | string;
	position: null | number | string;
	company: null | number | string;
	company_name?: null | number | string;
	company_registration_number?: null | number | string;
	company_country_registration?: null | number | string;
}

export interface IUserDataStatus {
	id: number;
	name: string;
}

export interface ICodeForTwoFaRequest {
	token: string;
}

export interface ICodeForTwoFaResponse {
	QR_Image: string;
	secret: string;
	reauthenticating: string;
}

export interface IPutPermissions {
	permissions: EPermission[];
	is_admin: boolean;
}

export enum EPermission {
	ViewHistory = 'view-history',
	DownloadReport = 'download-report',
	ViewStatement = 'view-statement',
	ViewTradeHistory = 'view-trade-history',
	CreateDeposit = 'create-deposit',
	CreateWithdraw = 'create-withdraw',
	ApproveDeposit = 'approve-deposit',
	ApproveWithdraw = 'approve-withdraw',
	CreateTrade = 'create-trade',
	ManageBeneficiary = 'manage-beneficiary',
}

export enum ERole {
	Viewer = 'viewer',
	Initiator = 'initiator',
	Approver = 'approver',
	Trader = 'trader',
	BeneficiaryCreator = 'beneficiary creator',
	Admin = 'admin',
	SuperAdmin = 'super admin',
}

export enum ERolePermissions {
	Viewer = 'viewer',
	Initiator = 'initiator',
	Approver = 'approver',
	BeneficiaryCreator = 'beneficiary creator',
	Trader = 'trader',
	Admin = 'admin',
}
