import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { statusNames } from '../../TransactionHistory/utils';
import DetailedViewCryptoForm from '../DetailedViewForm/DetailedViewCryptoForm';
import DefaultFields from '../DefaultFields/DefaultFields';
import { getCryptoDetailsRequest } from '../../../redux/reducers/detailedView/reducer';
import {
	getCryptoDetails,
	getDetailedViewLoading,
} from '../../../redux/reducers/detailedView/selectors';

export interface IDepositHistoryCryptoDetails {
	txId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DepositHistoryCryptoDetails: FC<IDepositHistoryCryptoDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	// const currentItemData = useSelector(getCryptoHistoryList);
	const cryptoDetails = useSelector(getCryptoDetails);
	const loading = useSelector(getDetailedViewLoading);

	// const userData = useSelector(getUserData);

	useEffect(() => {
		if (txId) {
			/* const params: IGetTransactionHistoryRequestPayload = {
				apiParams: {
					current_page: 1,
					per_page: 100000000,
					id: Number(txId),
				},
			};
			dispatch(getCryptoHistoryRequest(params)); */
			dispatch(getCryptoDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	/* useEffect(() => {
		dispatch(getUserRequest());
	}, [dispatch]); */

	if (!cryptoDetails && !loading) {
		return <div>No data</div>;
	}

	return (
		<>
			{!!cryptoDetails && (
				<div className="detailed-view" key={cryptoDetails.id}>
					<div className="input-wrap--col-2">
						<DefaultFields item={cryptoDetails} userData={cryptoDetails.user} />

						<CustomInput
							label="Currency"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${cryptoDetails.asset.code.toUpperCase()} ${cryptoDetails.asset.name}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Gross"
							value={`${roundingNumber(
								cryptoDetails.gross_fee,
								cryptoDetails.asset.code,
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							)} ${cryptoDetails.asset.code.toUpperCase()}`}
							type="text"
							disabled
						/>

						<CustomInput
							label="Fee"
							value={`${roundingNumber(
								cryptoDetails.fee,
								cryptoDetails.asset.code,
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							)} ${cryptoDetails.asset.code.toUpperCase()}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Net"
							value={`${toFixedNumber(
								cryptoDetails.net_fee,
								cryptoDetails.asset.code,
								true,
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							)} ${cryptoDetails.asset.code.toUpperCase()}`}
							type="text"
							disabled
						/>

						{/* {cryptoDetails.status === 'admin_pending' ||
						(cryptoDetails.status === 'pending' && (
							<CustomInput
								label="Type"
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								value={`${cryptoDetails.type}`}
								type="text"
								disabled
							/>
						))} */}
						<CustomInput
							label="Destination"
							value={
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								cryptoDetails.description ||
								cryptoDetails.destination ||
								cryptoDetails.address.address ||
								'-'
							}
							type="text"
							disabled
						/>
						<CustomInput
							label="Tx hash"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${cryptoDetails.tx_hash ? cryptoDetails.tx_hash : '-'}`}
							type="text"
							disabled
						/>
						{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
						<CustomInput label="Tx ID" value={`${cryptoDetails.id}`} type="text" disabled />
						<CustomInput
							label="Status"
							value={`${statusNames[cryptoDetails.status]}`}
							type="text"
							disabled
						/>
						<CustomInput
							label="Description"
							value={
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								cryptoDetails.description ||
								cryptoDetails.destination ||
								cryptoDetails.address.address ||
								'-'
							}
							type="text"
							disabled
						/>
						<CustomInput
							label="Reference"
							value={`${
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								cryptoDetails.reference ? cryptoDetails.reference : cryptoDetails.tx_hash || '-'
							}`}
							type="text"
							disabled
						/>
					</div>
					<DetailedViewCryptoForm item={cryptoDetails} />
				</div>
			)}
		</>
	);
};

export default DepositHistoryCryptoDetails;
