import React from 'react';

interface IProps {
	pivotAccountId?: number;
	color: string;
	name: string;
	onClick: (pivotAccountId?: number) => void;
}

const Item: React.FC<IProps> = ({ pivotAccountId, color, onClick, name }) => {
	const handleClick = () => {
		onClick(pivotAccountId);
	};

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<li className="workspace-item" onClick={handleClick}>
			<div className={`workspace-icon ${color}`}>
				<p>{name.slice(0, Math.min(2, name.length))}</p>
			</div>
			<span>{name}</span>
		</li>
	);
};

Item.defaultProps = {
	pivotAccountId: 0,
};

export default Item;
