import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { de } from 'date-fns/locale';
import TableHeader from './TableHeader/TableHeader';
import TableBody from './TableBody/TableBody';
import Pagination from '../TransactionHistory/Pagination/Pagination';
import PerPageSelect from '../../ui/PerPageSelect/PerPageSelect';
import UsersSearch from './UsersSearch';

// Импорты из вашего кода
import {
	getMultiAccountsMembers,
	getMultiAccountsMembersLoading,
	getMultiAccountsPage,
} from '../../redux/reducers/multiAccounts/selectors';
import {
	getMemberRequest,
	getMultiAccountsRequestParams,
} from '../../redux/reducers/multiAccounts/reducer';

type Params = {
	id: string;
};

const YourAccountsMain = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams<Params>();

	// Основной стейт для пагинации и любых других параметров
	const [query, setQuery] = useState({
		current_page: 1,
		per_page: 10,
	});
	// Селектор, откуда получаем список членов (PaginatedMembersResponse)
	const members = useSelector(getMultiAccountsMembers);
	const membersLoading = useSelector(getMultiAccountsMembersLoading);

	// Селектор с данными аккаунтов
	const multiAccountsPage = useSelector(getMultiAccountsPage);
	const multiAccountsPageSelected = multiAccountsPage?.data.find(
		(account) => account.id === Number(id),
	);

	// Изменение per_page (количество записей на странице)
	const handleSetPerPage = (value: number | undefined) => {
		setQuery((prev) => ({
			...prev,
			current_page: 1, // при смене количества записей сбрасываемся на 1-ю страницу
			per_page: value ?? 10, // Если выбрали 'All' (undefined), можно подставить своё число
		}));
	};

	// Изменение текущей страницы
	const handlePageChange = (page: number) => {
		setQuery((prev) => ({
			...prev,
			current_page: page,
		}));
	};

	// Вызываем getMemberRequest при изменении query или при смене выбранного аккаунта
	useEffect(() => {
		if (!multiAccountsPageSelected) return;

		const payload = {
			accountId: multiAccountsPageSelected.id,
			...query, // current_page, per_page и любые другие поля из query
		};
		// eslint-disable-next-line no-debugger
		dispatch(getMemberRequest(payload));
		dispatch(getMultiAccountsRequestParams(payload));
	}, [query, multiAccountsPageSelected, dispatch]);

	// Достаем общее число страниц и текущую страницу из members
	const pageCount = members?.last_page ?? 1;
	const currentPage = members?.current_page ?? 1;

	return (
		<div className="wallet-operations your-account-main">
			<div className="wallet-content-header wallet-content-header--mb-40">
				<div className="wallet-content-header__title">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button
						type="button"
						className="wallet-operations-header__back"
						onClick={() => history.goBack()}
					/>
					<p>{multiAccountsPageSelected?.account_name}</p>
					<span>Main</span>
				</div>
			</div>

			<div className="table-block table-block--mt-0">
				<div className="title-block">
					<p className="title">Members</p>
				</div>

				{/* Компонент поиска, если нужно корректировать query */}
				<UsersSearch total={10} setQuery={setQuery} tab="user" includeOneifyId />

				<div className="table-wrapper">
					<div className="table table--your-account-main">
						<TableHeader />
						{/* eslint-disable-next-line no-nested-ternary */}
						{membersLoading ? (
							<span style={{ display: 'block', textAlign: 'center', marginTop: '40px' }}>
								Loading...
							</span>
						) : members?.data?.length ? (
							members.data.map((member) => <TableBody key={member.id} item={member} />)
						) : (
							<span style={{ display: 'block', textAlign: 'center' }}>Members is empty</span>
						)}
					</div>
					<div className="table-footer">
						<div className="select-page">
							<PerPageSelect onChange={handleSetPerPage} />
						</div>
						{pageCount > 1 && (
							<div className="pagination-block">
								<Pagination
									pageCount={pageCount}
									currentPage={currentPage}
									onPageChange={handlePageChange}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default YourAccountsMain;
