import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUserRole, getUserRoles } from '../../redux/reducers/auth/selectors';
import { ERole } from '../../redux/reducers/auth/types';
import { navList } from '../../routes/routesList';
import { hasAnyRoleExcept } from '../../services/utils/checkRoles';

const HeaderButtons = () => {
	const userRoles = useSelector(getUserRoles);

	return (
		<div className="wallet-content-header__buttons flex flex-e flex-m">
			{hasAnyRoleExcept(userRoles, [
				ERole.Viewer,
				ERole.Approver,
				ERole.Initiator,
				ERole.BeneficiaryCreator,
			]) && (
				<Link to={navList.trade.path} className="btn btn-primary">
					Trade
				</Link>
			)}
			{hasAnyRoleExcept(userRoles, [
				ERole.Viewer,
				ERole.Approver,
				ERole.Trader,
				ERole.BeneficiaryCreator,
			]) && (
				<>
					<Link to={navList.depositFiat.path} className="btn btn-primary btn-primary--blue300">
						Deposit
					</Link>
					<Link to={navList.withdrawFiat.path} className="btn btn-primary btn-primary--blue100">
						Withdraw
					</Link>
				</>
			)}
		</div>
	);
};

export default HeaderButtons;
