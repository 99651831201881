import React from 'react';
import HeaderButtons from '../../../layouts-elements/HeaderButtons';

const HistoryHeader = () => {
	// const { url: matchUrl } = useRouteMatch();

	return (
		<div className="wallet-content-header wallet-content-header--mb-40">
			<div className="wallet-content-header__title">
				<p>Trade History</p>
			</div>
			<HeaderButtons />
		</div>
	);
};

export default HistoryHeader;
