import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { ILocalFilter } from '../types';

interface IProps {
	localFilter: ILocalFilter;
	changeFilter: (text: string) => void;
}

const TableHeader: FC<IProps> = ({ localFilter, changeFilter }) => {
	const location = useLocation();
	const filterActiveUp = (text: string) => {
		if (localFilter.state === 1 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	const filterActiveDown = (text: string) => {
		if (localFilter.state === 2 && localFilter.value === text) {
			return 'active';
		}
		return '';
	};
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<p>Timestamp</p>
				</div>
				<div className="td">
					<p>Account ID</p>
				</div>
				{/* <div className="td">Type</div> */}
				<div className="td">
					<p>Currency</p>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('gross_fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Gross
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('gross_fee')}`} />
							<div className={`arrow-top ${filterActiveUp('gross_fee')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Fee
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('fee')}`} />
							<div className={`arrow-top ${filterActiveUp('fee')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<button
						onClick={() => {
							changeFilter('net_fee');
						}}
						type="button"
						className="td-sorting-arrow-button"
					>
						Net
						<span className="td-sorting">
							<div className={`arrow-bottom ${filterActiveDown('net_fee')}`} />
							<div className={`arrow-top ${filterActiveUp('net_fee')}`} />
						</span>
					</button>
				</div>
				<div className="td">
					<p>{location.pathname.includes('withdrawal-fiat') ? 'Destination' : 'Destination'}</p>
				</div>
				{location.pathname.includes('withdrawal-fiat') ? (
					<div className="td">
						<p>Beneficiary</p>
					</div>
				) : null}
				<div className="td">
					<p>Tx ID</p>
				</div>
				<div className="td">
					<p className="justify-content-center">Status</p>
				</div>
				<div className="td">
					<p className="justify-content-center">Actions</p>
				</div>
			</div>
		</div>
	);
};

export default TableHeader;
