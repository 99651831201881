import { http } from 'services/http';
import { endpoint } from 'services/endpoint';
import { ITransactionsApi } from './types';

export const transactions: ITransactionsApi = {
	getCryptoHistory: (params) =>
		http.get(endpoint.transactions.GET_CRYPTO_HISTORY, { params }).then(({ data }) => data),
	getFiatHistory: (params) =>
		http.get(endpoint.transactions.GET_FIAT_HISTORY, { params }).then(({ data }) => data),
	getTradeHistory: (params) =>
		http.get(endpoint.transactions.GET_TRADE_HISTORY, { params }).then(({ data }) => data),
	getTradeQuote: (payload) =>
		http.post(endpoint.transactions.GET_TRADE_QUOTE, payload).then(({ data }) => data),
	getTradeExchangeRate: (payload) =>
		http.post(endpoint.transactions.GET_TRADE_EXCHANGE_RATE, payload).then(({ data }) => data),
	makeTrade: (payload) =>
		http.post(endpoint.transactions.MAKE_TRADE, payload).then(({ data }) => data),
	getTopPair: () => http.get(endpoint.transactions.GET_TOP_PAIR).then(({ data }) => data),
	createFiatWithdraw: (payload) => http.post(endpoint.transactions.CREATE_FIAT_WITHDRAW, payload),
	createCryptoWithdraw: (payload) =>
		http.post(endpoint.transactions.CREATE_CRYPTO_WITHDRAW, payload),
	getFeesByAsset: (payload) =>
		http.get(endpoint.transactions.GET_FEES_BY_ASSET(payload)).then(({ data }) => data),
	getLimitsByAsset: (payload) =>
		http.get(endpoint.transactions.GET_LIMITS_BY_ASSET(payload)).then(({ data }) => data),
	postFiatDepositInvoiceCreate: (payload) =>
		http
			.post(endpoint.transactions.POST_FIAT_DEPOSIT_INVOICE_CREATE, payload)
			.then(({ data }) => data),
	getInvoiceData: (url) => http.get(url).then(({ data }) => data),
	getLedgerTransactions: ({ ...params }) =>
		http.get(endpoint.transactions.GET_LEDGER_TRANSACTIONS, { params }).then(({ data }) => data),

	approveCryptoTransaction: (payload) =>
		http.post(endpoint.transactions.APPROVE_CRYPTO_TRANSACTION, payload).then(({ data }) => data),

	approveFiatTransaction: (payload) =>
		http.post(endpoint.transactions.APPROVE_FIAT_TRANSACTION, payload).then(({ data }) => data),

	cancelApprovalCryptoWithdraw: (payload) =>
		http
			.post(endpoint.transactions.CANCEL_APPROVAL_CRYPTO_WITHDRAW, payload)
			.then(({ data }) => data),

	cancelApprovalFiatWithdraw: (payload) =>
		http
			.post(endpoint.transactions.CANCEL_APPROVAL_FIAT_WITHDRAW, payload)
			.then(({ data }) => data),

	cancelApprovalFiatDeposit: (payload) =>
		http.post(endpoint.transactions.CANCEL_APPROVAL_FIAT_DEPOSIT, payload).then(({ data }) => data),
};
