import React, { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory } from 'react-router';
import { Formik, Form, Field, ErrorMessage } from 'formik'; // <-- добавили ErrorMessage
import Input from 'ui/Formik/Input';
import PhoneInputField from 'ui/PhoneInputField';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from 'redux/reducers/auth/selectors';
import { IPopUp } from '../types';
import { accountsInviteRequestRequest } from '../../../redux/reducers/multiAccounts/reducer';
import {
	getMultiAccountsSelectId,
	getMultiRequestedParams,
} from '../../../redux/reducers/multiAccounts/selectors';
import { notificationsInfoFields } from '../../../services/utils/ipuntFields/ipuntFields';

interface IParams {
	fname: string;
	lname: string;
	email: string;
	phone_number: string;
	company_name: string;
	roles: string[];
	message: string;
	account_id: string;
}

const RequestNewMemberPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [counterStateCode, setStateCounterCode] = useState('US');
	const [phonePrefix, setPhonePrefix] = useState<string>('+1');
	const multiAccountsSelectId = useSelector(getMultiAccountsSelectId);
	const multiRequestedParams = useSelector(getMultiRequestedParams);
	console.log(multiRequestedParams);

	const onChange = (value: string, counterCode: string) => {
		setPhonePrefix(value);
		setStateCounterCode(counterCode);
	};

	const initialValues = {
		email: '',
		first_name: '',
		last_name: '',
		phone: '',
		company_name: '',
		message: '',
		selectedOptions: [],
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required('Email is required')
			.email('Invalid email format')
			.max(60, 'Email must be at most 60 characters'),
		first_name: yup
			.string()
			.min(1, 'First name must be at least 1 character')
			.max(50, 'First name must be at most 50 characters')
			.required('First name is required'),
		last_name: yup
			.string()
			.min(1, 'Last name must be at least 1 character')
			.max(50, 'Last name must be at most 50 characters')
			.required('Last name is required'),
		phone: yup
			.string()
			.max(20, notificationsInfoFields.phone.max)
			.matches(/^\d{8,20}$/, notificationsInfoFields.phone.matches),
		company_name: yup
			.string()
			.min(1, 'Company name must be at least 1 character')
			.max(50, 'Company name must be at most 50 characters'),
		message: yup
			.string()
			.min(2, 'Message must be at least 2 characters')
			.max(255, 'Message must be at most 255 characters'),
		selectedOptions: yup
			.array()
			.min(1, 'Please select at least one role')
			.required('Please select at least one role'),
	});

	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="modal">
				<div className="popup">
					{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
					<button type="button" className="popup__close-btn" onClick={closeModal} />
					<div className="popup-header">
						<p className="popup-header__title">Request new member</p>
					</div>
					<Formik
						validationSchema={validationSchema}
						initialValues={initialValues}
						onSubmit={(obj, { resetForm, setSubmitting }) => {
							if (multiAccountsSelectId) {
								const params: IParams = {
									fname: obj.first_name,
									lname: obj.last_name,
									email: obj.email,
									phone_number: obj.phone ? phonePrefix + obj.phone : '',
									company_name: obj.company_name,
									roles: obj.selectedOptions,
									message: obj.message,
									account_id: String(multiAccountsSelectId),
								};

								dispatch(accountsInviteRequestRequest({ params, multiRequestedParams }));
								setSubmitting(false);
								closeModal();
								resetForm();
							}
						}}
						enableReinitialize
						validateOnBlur
					>
						{({ setFieldValue }) => (
							<Form className="form">
								<div className="popup-body">
									<Field
										title="Enter First Name"
										type="text"
										placeholder="First Name"
										name="first_name"
										required
										component={Input}
									/>
									<Field
										title="Enter Last Name"
										type="text"
										placeholder="Last Name"
										name="last_name"
										required
										component={Input}
									/>
									<Field
										title="Enter Email Address"
										type="email"
										placeholder="Email Address"
										name="email"
										required
										component={Input}
									/>
									<p className="input__name">Phone Number</p>
									<PhoneInputField
										counterStateCode={counterStateCode}
										onChange={onChange}
										value={phonePrefix}
										enableSearch
										dropdownClass="phone-dropdown"
										searchClass="searchfield"
									>
										<Field
											type="tel"
											placeholder="Enter Phone Number"
											name="phone"
											required
											component={Input}
											setValue={setFieldValue}
										/>
									</PhoneInputField>
									<Field
										title="Company name"
										type="text"
										placeholder="Enter Company name"
										name="company_name"
										required
										component={Input}
									/>

									<div role="group" aria-labelledby="checkbox-group">
										<p className="input__name">Role</p>
										<div className="checkbox">
											<label>
												<Field type="checkbox" name="selectedOptions" value="Viewer" />
												<span className="custom-checkbox" />
												Viewer
											</label>
											<label>
												<Field type="checkbox" name="selectedOptions" value="Trader" />
												<span className="custom-checkbox" />
												Trader
											</label>
											<label>
												<Field type="checkbox" name="selectedOptions" value="Initiator" />
												<span className="custom-checkbox" />
												Initiator
											</label>
											<label>
												<Field type="checkbox" name="selectedOptions" value="Approver" />
												<span className="custom-checkbox" />
												Approver
											</label>
											<label>
												<Field type="checkbox" name="selectedOptions" value="beneficiary_creator" />
												<span className="custom-checkbox" />
												Beneficiary Creator
											</label>
										</div>
										<ErrorMessage name="selectedOptions">
											{(msg) => (
												<div className="input-notify">
													<div className="input__notification input__notification--type2 input__notification--ta-r">
														<p>{msg}</p>
													</div>
												</div>
											)}
										</ErrorMessage>
									</div>

									<Field
										title="Message"
										type="textarea"
										placeholder="Enter Message"
										name="message"
										required
										component={Input}
										className="mb-20"
									/>
								</div>
								<div className="popup-footer">
									<div className="popup-submit mt-0">
										<button type="submit" className="btn btn-primary btn--full">
											Send
										</button>
									</div>
								</div>
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</Popup>
	);
};

export default RequestNewMemberPopUp;
